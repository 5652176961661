import React, {
  FC,
  useRef,
  useState,
  useEffect,
  createContext,
  useCallback
} from 'react';

export const StickyContext = createContext({
  subscribe: (_handler: any) => {},
  unsubscribe: (_handler: any) => {},
  getParent: (): HTMLDivElement | void | null => {}
});

interface IContainer {
  children: React.ReactNode;
}

const Container: FC<IContainer> = ({ children, ...props }) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [subscribers, setSubscribers] = useState<any[]>([]);
  const framePending = useRef(false);
  const rafHandle = useRef<number | null>(null);

  const subscribe = useCallback((handler: any) => {
    setSubscribers((prev) => [...prev, handler]);
  }, []);

  const unsubscribe = useCallback((handler: any) => {
    setSubscribers((prev) => prev.filter((current) => current !== handler));
  }, []);

  const notifySubscribers = useCallback(
    (evt: { currentTarget: any }) => {
      if (!framePending.current) {
        const { currentTarget } = evt;

        rafHandle.current = requestAnimationFrame(() => {
          if (!nodeRef.current) return;

          framePending.current = false;
          const { top, bottom } = nodeRef.current.getBoundingClientRect();

          subscribers.forEach((handler) =>
            handler({
              distanceFromTop: top,
              distanceFromBottom: bottom,
              eventSource: currentTarget === window ? document.body : nodeRef.current
            })
          );
        });
        framePending.current = true;
      }
    },
    [subscribers]
  );

  const getParent = useCallback(() => nodeRef.current, []);

  useEffect(() => {
    const events = [
      'resize',
      'scroll',
      'touchstart',
      'touchmove',
      'touchend',
      'pageshow',
      'load'
    ];

    events.forEach((event) => window.addEventListener(event, notifySubscribers));

    return () => {
      if (rafHandle.current) {
        cancelAnimationFrame(rafHandle.current);
        rafHandle.current = null;
      }
      events.forEach((event) => window.removeEventListener(event, notifySubscribers));
    };
  }, [notifySubscribers]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StickyContext.Provider value={{ subscribe, unsubscribe, getParent }}>
      <div {...props} ref={nodeRef} onScroll={notifySubscribers}>
        {children}
      </div>
    </StickyContext.Provider>
  );
};

export default Container;
