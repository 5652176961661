import { useCallback, useReducer } from 'react';

export const useSelect = ({
  multiple,
  defaultPickedIds
}: {
  multiple: boolean;
  defaultPickedIds?: string[];
}): {
  pick: (lanId: number | string) => void;
  onReset: () => void;
  pickedIds: tState;
} => {
  const [pickedIds, dispatch] = useReducer(reducer, defaultPickedIds || []);
  const onReset = () => dispatch({ type: 'reset' });
  const pick = useCallback(
    (lanId: number | string) => {
      if (multiple) {
        dispatch({ type: 'pick', payload: `${lanId}` });
      } else {
        dispatch({ type: 'pickOne', payload: `${lanId}` });
      }
    },
    [multiple, dispatch]
  );

  return {
    pick,
    onReset,
    pickedIds
  };
};

type tAction =
  | { type: 'addSelected'; payload: string }
  | { type: 'removeSelected'; payload: string }
  | { type: 'pick'; payload: string }
  | { type: 'pickOne'; payload: string }
  | { type: 'reset' };

type tState = string[];

function reducer(state: tState, action: tAction): tState {
  switch (action.type) {
    case 'addSelected':
      return [...state, action.payload];
    case 'removeSelected':
      return state.filter((key) => key !== action.payload);
    case 'pickOne':
      return [action.payload];
    case 'pick': {
      const isExist = state.find((key: string | number) => key === action.payload);
      if (isExist) {
        return state.filter((key) => key !== action.payload);
      } else {
        return [...state, action.payload];
      }
    }
    case 'reset':
    default:
      return [];
  }
}
