import React from 'react';
import styled from 'styled-components';
import { Translate } from 'i18n';
import MessagesIcon from './icons/Messages';

function MobileChatIcon({ className }: { className?: string }) {
  return (
    <SWrapper className={className}>
      <lingu-button>
        <MessagesIcon />

        <SCaption>
          <Translate str="frontend.mobile_tabs.messages" />
        </SCaption>
      </lingu-button>
    </SWrapper>
  );
}

export default MobileChatIcon;

declare global {
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface IntrinsicElements {
      'lingu-badge': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { styles?: string };

      'lingu-button': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { styles?: string };
    }
  }
}

const SWrapper = styled.div`
  display: flex;
  justify-content: center;

  & lingu-button::part(button) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--color-grey);
    font-size: 0.7rem;
    white-space: nowrap;
    line-height: 1;
    outline: none;
    width: 100%;
    height: 100%;
  }

  &.active {
    text-decoration: none;
  }
`;

const SCaption = styled.p`
  padding: 0.2rem 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
