import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

interface IProps {
  className?: string;
  src: string | null;
  srcSet?: string;
  alt?: string;
  lazy?: boolean;
  fallbackImage?: string | null;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  title?: string;
}

const Picture: React.FC<IProps> = ({
  className,
  src,
  srcSet,
  alt,
  onError,
  fallbackImage,
  title,
  lazy = false
}) => {
  const [imageSrc, setImageSrc] = useState(src || fallbackImage);

  useEffect(() => {
    if (!src) {
      fallbackImage && setImageSrc(fallbackImage);
    } else {
      setImageSrc(src);
    }
  }, [src, fallbackImage]);

  const handleError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
      onError && onError(event);
      fallbackImage && setImageSrc(fallbackImage);
    },
    [fallbackImage, onError]
  );

  if (!imageSrc) return null;

  return (
    <picture>
      {srcSet && <source srcSet={srcSet} type="image/webp" />}
      <SImg
        loading={lazy ? 'lazy' : 'eager'}
        src={imageSrc}
        alt={alt}
        onError={handleError}
        title={title}
        className={className}
      />
    </picture>
  );
};

export default Picture;

const SImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
