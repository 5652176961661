import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

interface IHeadlessModal {
  children: ReactNode;
  modalKey: string;
  show: boolean;
  onHide?: () => void;
  backdropClassName?: string;
  containerClassName?: string;
  dialogClassName?: string;
}

const HeadlessModal: FC<IHeadlessModal> = ({
  children,
  modalKey,
  show,
  onHide,
  backdropClassName = '',
  containerClassName = '',
  dialogClassName = ''
}) => {
  const [visible, setVisible] = useState<boolean>(show ?? false);

  useEffect(() => {
    setVisible(show);
  }, [show, setVisible]);

  const handleHide = useCallback(() => {
    setVisible(false);
    onHide?.();
  }, [onHide, setVisible]);

  return (
    <Dialog
      open={visible}
      onClose={handleHide}
      className={`lingu-modal ${modalKey}`}
      transition
    >
      <DialogBackdrop
        className={`${modalKey}-backdrop ln-z-[100] ln-fixed ln-inset-0 ${backdropClassName}`}
      />

      <div
        className={`${modalKey}-content ln-z-[100] ln-fixed ln-inset-0 ln-flex ln-w-screen ${containerClassName}`}
      >
        <DialogPanel className={`${modalKey}-dialog ${dialogClassName}`}>
          {children}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default HeadlessModal;
