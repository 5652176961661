import React, { FC } from 'react';
import { Translate } from 'i18n';
import { APP_SERVER_URL } from 'students/constants';
import LinkElem from 'students/views/shared/components/LinkElem';
import UrlIcon from 'students/views/shared/components/UrlIcon/UrlIcon';
import rightArrowIcon from 'students/views/shared/assets/icons/arrow_right_icon.svg';
import TeacherIcon from './assets/teacher.svg';

const APP_URL = new URL(APP_SERVER_URL);
export const TEACHER_APP_URL = `${APP_URL.protocol}//app.${APP_URL.host}`;

const GoToTeacherArea: FC = () => {
  return (
    <LinkElem
      to={TEACHER_APP_URL}
      className="ln-flex ln-items-center ln-h-13 ln-bg-white/16 ln-mt-8 ln-text-white ln-text-sm ln-px-3 ln-rounded-2xl hover:ln-bg-white/30 hover:ln-text-white hover:ln-no-underline ln-duration-300 ln-transition-colors"
    >
      <i
        className="ln-w-7 ln-h-7 ln-max-w-7 ln-inline-block ln-mask-position-center ln-mask-no-repeat ln-mask-size-contain ln-bg-white ln-bg-contain"
        style={{
          WebkitMaskImage: `url(${TeacherIcon})`
        }}
      />
      <span className="ln-mx-2 ln-flex-1">
        <Translate str="frontend.profile.navigation.go_to_teacher_area" />
      </span>
      <UrlIcon
        url={rightArrowIcon}
        color="rgba(255, 255, 255, 0.5)"
        height="1.5rem"
        width="1.5rem"
      />
    </LinkElem>
  );
};

export default GoToTeacherArea;
