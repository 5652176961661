import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { Translate } from 'i18n';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FC, ReactElement, RefObject, useCallback, useRef, useState } from 'react';
import { DEFAULT_FONT_SIZE } from 'students/views/shared/providers/Preferences/Config';
import {
  getPreference,
  setDocumentFontSize,
  setPreference
} from 'students/views/shared/providers/Preferences/helpers';
import { Preferences } from 'students/views/shared/providers/Preferences/models';
import styled from 'styled-components';
import { MAX_SIZE, MIN_SIZE, SIZE_STEP } from './constants';

interface IDesktopFontSizeSwitcher {
  children?: (ref: RefObject<HTMLButtonElement>) => ReactElement;
}

const DesktopFontSizeSwitcher: FC<IDesktopFontSizeSwitcher> = ({ children }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState<string>(
    getPreference(Preferences.FontSize, DEFAULT_FONT_SIZE)
  );

  const switchFontSize = useCallback(
    (newFontSize: any) => {
      setFontSize(setPreference(Preferences.FontSize, newFontSize));
      setDocumentFontSize(newFontSize);
    },
    [setFontSize]
  );

  return (
    <Popover>
      <PopoverButton as="div">
        {children ? (
          children(buttonRef)
        ) : (
          <button
            type="button"
            className="ln-text-blue ln-text-sm ln-rounded hover:ln-text-blue-800 focus:ln-outline focus:ln-outline-offset-4 focus:ln-outline-blue focus:ln-outline-[3px]"
            data-testid="students/Member/components/DesktopFontSizeSwitcher/ToggleButton"
            ref={buttonRef}
          >
            <Translate str="frontend.member.settings.pages.sound_and_visual.select" />
          </button>
        )}
      </PopoverButton>

      <PopoverPanel id="font-size-tooltip" anchor="bottom" className="ln-z-[100]">
        <div
          ref={tooltipRef}
          className="font-size-tooltip-inner ln-flex ln-flex-col ln-items-center ln-w-80 ln-bg-grey-n-4 ln-rounded-xl ln-px-4 ln-py-8"
        >
          <p className="ln-text-base ln-p-0 ln-mb-10 ln-w-11/12 ln-text-center ln-text-white/80">
            <Translate str="frontend.member.settings.pages.sound_and_visual.text_example" />
          </p>

          <SSlider
            min={MIN_SIZE}
            max={MAX_SIZE}
            step={SIZE_STEP}
            dots
            value={parseInt(fontSize, 10)}
            onChange={switchFontSize}
          />
        </div>
      </PopoverPanel>
    </Popover>
  );
};

export default DesktopFontSizeSwitcher;

const SSlider = styled(Slider)`
  width: calc(100% - 20px) !important;

  &:before {
    content: 'A';
    position: absolute;
    top: -26px;
    left: 0;
    font-size: 1rem;
    font-weight: 700;
    color: var(--heading-text-color);
  }

  &:after {
    content: 'A';
    position: absolute;
    top: -44px;
    right: 0;
    font-size: 30px;
    font-weight: 700;
    color: var(--heading-text-color);
  }

  .rc-slider-rail {
    height: 10px;
    background: var(--common-font-size-desktop-range-rail-background);
    box-shadow: inset 0 2px 0 rgba(0, 9, 40, 0.1);
  }

  .rc-slider-track {
    height: 10px;
    background: transparent;
  }

  .rc-slider-handle {
    background: var(--common-font-size-range-handle-background);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: none;
    height: 28px;
    width: 28px;
    margin-block-start: -10px;

    &:focus {
      outline: 3px solid var(--color-blue-btn-focus-outline-color);
      outline-offset: 3px;
    }
  }

  .rc-slider-step {
    height: 6px;

    & > span:first-child {
      left: 6px !important;
    }

    & > span:last-child {
      left: calc(100% - 4px) !important;
    }
  }

  .rc-slider-dot {
    background: var(--common-font-size-desktop-range-dot-background);
    border: none;
    height: 6px;
    width: 6px;
  }
`;
