import cn from 'classnames';
import { t } from 'i18n';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import LinkElem from 'students/views/shared/components/LinkElem';
import BackIcon from '../navigation/components/icons/BackIcon';
import CloseIcon from '../navigation/components/icons/CloseIcon';
import { navigationMediaQuery } from '../navigation/config';

interface IBackNavigationButton {
  to: string;
  variant?: 'back' | 'close';
  preventDefaultPosition?: boolean;
  className?: string;
}

const BackNavigationButton: FC<IBackNavigationButton> = ({
  to,
  variant = 'back',
  preventDefaultPosition,
  className
}) => {
  const linkTitle =
    variant === 'back' ? t('frontend.auth.back') : t('frontend.lesson_page.close');
  const icon = variant === 'back' ? <SBackIcon /> : <SCloseIcon />;
  return (
    <SBackLink
      to={to}
      className={cn(className, { isTopLeft: !preventDefaultPosition }, 'dir-auto')}
      title={linkTitle}
    >
      <SBackIconContainer>{icon}</SBackIconContainer>
    </SBackLink>
  );
};

export default BackNavigationButton;

const SBackLink = styled(LinkElem)`
  z-index: 10;
  display: flex;
  padding: 8px;

  &.isTopLeft {
    position: absolute;
    top: 20px;
    inset-inline-start: 12px;
  }

  ${navigationMediaQuery} {
    display: none;
  }
`;

const SBackIconContainer = styled.span`
  display: flex;
  background: none;
  border: none;
  outline: none;
  color: var(--heading-text-color);

  ${navigationMediaQuery} {
    display: none;
  }
`;

const iconStyles = css`
  stroke: var(--heading-text-color);
`;

const SBackIcon = styled(BackIcon)`
  ${iconStyles}
`;

const SCloseIcon = styled(CloseIcon)`
  ${iconStyles}
`;
