import { navigationBreakPoint } from '../theme/breakpoints';

import HomeIcon from './assets/home.svg';
import AssignmentsIcon from './assets/assignments.svg';
import BoardsIcon from './assets/boards.svg';
import MessagesIcon from './assets/messages.svg';
import CatalogIcon from './assets/materials.svg';
import ClassesIcon from './assets/classes.svg';

export interface INavigationItem {
  name: string; // as the caption locale key
  path: string;
  color: string;
  isDynamic?: boolean;
  badgeCount?: number;
  icon?: string;
}

export const homeItem: INavigationItem = {
  name: 'home',
  path: '/',
  color: '#05C3F1',
  icon: HomeIcon
};

export const catalogItem: INavigationItem = {
  name: 'catalog',
  path: '/catalog',
  color: '#FBBC25',
  icon: CatalogIcon
};

export const learningMaterialsItem: INavigationItem = {
  name: 'learning_materials',
  path: '/learning-materials',
  color: '#FBBC25',
  icon: CatalogIcon
};

/* TODO: Remove meetingsItem after liveClassesItem is ready to use */
export const meetingsItem: INavigationItem = {
  name: 'meetings',
  path: '/meetings',
  color: '#D12379',
  icon: ClassesIcon
};

export const liveClassesItem: INavigationItem = {
  name: 'live_classes',
  path: '/live-classes',
  color: '#F7409B',
  icon: ClassesIcon
};

export const assignmentsItem: INavigationItem = {
  name: 'assignments',
  path: '/assignments',
  color: '#9C75FF',
  icon: AssignmentsIcon
};

export const boardsItem: INavigationItem = {
  name: 'boards',
  path: '/boards',
  color: '#D2F337',
  icon: BoardsIcon
};

const _settingsItem: INavigationItem = {
  name: 'settings',
  path: '/settings',
  color: '#BED62F'
};

export const tabBarItems: INavigationItem[] = [homeItem, boardsItem, assignmentsItem];

export { HomeIcon, CatalogIcon, AssignmentsIcon, BoardsIcon, MessagesIcon };

export const navigationMediaQuery = `@media(min-width:${navigationBreakPoint}px)`;
