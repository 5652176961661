import cn from 'classnames';
import { FC, Suspense } from 'react';
import { useTypedSelector } from 'students/stores/_utils';
import { userSelectors } from 'students/stores/user';
import lazyWithRetry from 'students/views/shared/HOCs/lazyWithRetry';
import lightLogo from 'students/views/shared/assets/lingu-light-logo.svg';
import { styleInnerButton } from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import styled from 'styled-components';
import LinkElem from '../LinkElem';
import { BackArrowIcon } from '../icons';
import Burger from './Burger';
import UserProfile from './UserProfile';

const AuthModal = lazyWithRetry(() => import('students/views/screens/Auth'));

interface IProps {
  className?: string;
  backTo?: string;
}

// Used on Public pages
const PublicNavbar: FC<IProps> = ({ className, backTo }) => {
  const user = useTypedSelector(userSelectors.selectProfile);

  return (
    <div className={cn('ln-pt-safe', className)}>
      <nav className="ln-py-3 md:ln-py-5 ln-px-1 md:ln-px-8 ln-flex ln-items-center ln-justify-between ln-bg-black/40">
        {backTo ? (
          <LinkElem to={backTo} className="ln-block md:ln-hidden ln-ps-4">
            <BackArrowIcon
              color="var(--color-white)"
              height="0.875rem"
              width="1.25rem"
              className="dir-auto"
            />
          </LinkElem>
        ) : (
          <div className="ln-block md:ln-hidden">
            <Burger user={user} />
          </div>
        )}

        <div className="ln-hidden md:ln-block ln-mr-auto">
          <a href="/" title="Lingu" className="ln-me-auto ln-p-0 ln-pt-safe">
            <SLogo src={lightLogo} alt="Lingu" />
          </a>
        </div>

        <div className="ln-flex ln-items-center">
          <SUserProfileWrapper>
            <UserProfile user={user} />
          </SUserProfileWrapper>
        </div>

        <Suspense fallback="">{!user && <AuthModal />}</Suspense>
      </nav>
    </div>
  );
};

export default PublicNavbar;

const SUserProfileWrapper = styled.div`
  button {
    ${styleInnerButton()} {
      padding: 0.75rem 1.75rem;
    }

    @media (min-width: ${({ theme }) => theme.linguBptMd}) {
      font-size: 1.125rem;
    }
  }
`;

const SLogo = styled.img`
  width: 6.75rem;
  height: 3rem;
`;
