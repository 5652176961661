import styled from 'styled-components';

export const SRichEditorTextWrapper = styled.div`
  font-family: 'Inter', 'Product Sans', 'Helvetica', 'Arial', sans-serif !important;

  table[style] {
    width: 100% !important;
  }

  table {
    border: 1px solid #edf2f9;
  }

  table td,
  table th {
    padding: 0.75rem;
    vertical-align: top;
    border: 1px solid #edf2f9;
  }

  table tr td:first-child {
    padding-left: 1.75rem;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    list-style-position: inside;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }

  img {
    max-width: 100%;
    height: auto;
    display: inline-block;
  }

  img.emoji-img-inline {
    display: inline-block;
    margin-block-start: -0.3em;
    height: 1.2em;
    width: auto;
  }

  .notranslate {
    background-color: inherit !important;
  }
`;
