import cn from 'classnames';
import { FC, Suspense } from 'react';
import { useTypedSelector } from 'students/stores/_utils';
import { userSelectors } from 'students/stores/user';
import lazyWithRetry from 'students/views/shared/HOCs/lazyWithRetry';
import lightLogo from 'students/views/shared/assets/lingu-light-logo.svg';
import styled from 'styled-components';
import LinkElem from '../LinkElem';
import { BackArrowIcon } from '../icons';
import UserProfile from './UserProfile';

const AuthModal = lazyWithRetry(() => import('students/views/screens/Auth'));

interface IProps {
  className?: string;
  backTo?: string;
}

// used on Lesson Page
const Navbar: FC<IProps> = ({ className, backTo }) => {
  const user = useTypedSelector(userSelectors.selectProfile);

  return (
    <SNavbar className={cn(className)}>
      <nav className="ln-py-3 md:ln-py-5 ln-px-1 md:ln-px-8 ln-flex ln-items-center ln-justify-between ln-bg-black/30">
        {backTo ? (
          <LinkElem to={backTo} className="ln-block md:ln-hidden ln-ps-4">
            <BackArrowIcon
              color="var(--color-white)"
              height="0.875rem"
              width="1.25rem"
              className="dir-auto"
            />
          </LinkElem>
        ) : null}

        <div className="ln-hidden md:ln-block ln-mr-auto">
          <a href="/" title="Lingu" className="ln-me-auto ln-p-0 ln-pt-safe">
            <SLogo src={lightLogo} alt="Lingu" />
          </a>
        </div>

        <div className="ln-flex ln-items-center">
          <UserProfile user={user} />
        </div>

        <Suspense fallback="">{!user && <AuthModal />}</Suspense>
      </nav>
    </SNavbar>
  );
};

export default Navbar;

const SNavbar = styled.div`
  .navbar {
    @media (max-width: ${({ theme }) => theme.linguBrPts.md - 1}px) {
      background-color: transparent !important;
    }
  }

  .target-dropdown__target-button {
    color: white !important;
  }
`;

const SLogo = styled.img`
  width: 6.75rem;
  height: 3rem;
`;
