import cn from 'classnames';
import { Translate } from 'i18n';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { styleInnerButton } from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import styled from 'styled-components';

import SMemberButton from './MemberButton';
import NoResultsMessage from './NoResultsMessage';

interface INoResultsBlock {
  withReset?: boolean;
  onReset?: () => void;
  className?: string;
}

const NoResultsBlock: FC<INoResultsBlock> = ({ withReset, onReset, className }) => {
  const history = useHistory();

  const navigateToTrainingSection = useCallback(() => {
    history.push('/settings/training');
  }, [history]);

  return (
    <SNoResultsBlock className={cn(className)}>
      <SNoResultsMessage
        text={<Translate str="frontend.member.catalog.no_results" />}
        subText={
          <Translate
            str={
              withReset
                ? 'frontend.member.catalog.try_adjusting_your_search'
                : 'frontend.member.catalog.try_adjusting_your_training_settings'
            }
          />
        }
      />

      {withReset ? (
        <SActionButton onClick={onReset} variant="primary">
          <Translate str="frontend.member.catalog.remove_all_filters" />
        </SActionButton>
      ) : null}

      <SActionButton onClick={navigateToTrainingSection} variant="secondary">
        <Translate str="frontend.member.catalog.update_training_settings" />
      </SActionButton>
    </SNoResultsBlock>
  );
};

export default NoResultsBlock;

const SNoResultsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SNoResultsMessage = styled(NoResultsMessage)`
  margin-block-end: 50px;
`;

const SActionButton = styled(SMemberButton)`
  font-size: 1rem;
  min-height: 48px;
  max-height: 48px;
  margin-block-end: 20px;
  text-transform: initial;
  min-width: 310px;

  ${styleInnerButton()} {
    padding: 0.5rem 1.75rem;
  }
`;
