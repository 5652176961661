import { tLevel } from '@adeptlms/lingu-students-react-shared';

import level1Icon from './assets/levels/1-level.svg';
import level1ColorIcon from './assets/levels/1-level-color.svg';

import level2Icon from './assets/levels/2-level.svg';
import level2ColorIcon from './assets/levels/2-level-color.svg';

import level3Icon from './assets/levels/3-level.svg';
import level3ColorIcon from './assets/levels/3-level-color.svg';

import level4Icon from './assets/levels/4-level.svg';
import level4ColorIcon from './assets/levels/4-level-color.svg';

import level5Icon from './assets/levels/5-level.svg';
import level5ColorIcon from './assets/levels/5-level-color.svg';

export const availableLevels: tLevel[] = ['a1', 'a2', 'b1', 'b2', 'c1'];

export const levelsData = new Map<
  tLevel,
  {
    value: tLevel;
    levelShortName: string;
    nameLocaleStr: string;
    nameShortLocaleStr: string;
    icon: string;
    colorIcon: string;
  }
>([
  [
    'a1',
    {
      value: 'a1',
      levelShortName: 'A1',
      nameShortLocaleStr: 'frontend.language.levels.names.a1',
      nameLocaleStr: 'frontend.profile.onboarding.language_levels.a1',
      icon: level1Icon,
      colorIcon: level1ColorIcon
    }
  ],
  [
    'a2',
    {
      value: 'a2',
      levelShortName: 'A2',
      nameShortLocaleStr: 'frontend.language.levels.names.a2',
      nameLocaleStr: 'frontend.profile.onboarding.language_levels.a2',
      icon: level2Icon,
      colorIcon: level2ColorIcon
    }
  ],
  [
    'b1',
    {
      value: 'b1',
      levelShortName: 'B1',
      nameShortLocaleStr: 'frontend.language.levels.names.b1',
      nameLocaleStr: 'frontend.profile.onboarding.language_levels.b1',
      icon: level3Icon,
      colorIcon: level3ColorIcon
    }
  ],
  [
    'b2',
    {
      value: 'b2',
      levelShortName: 'B2',
      nameShortLocaleStr: 'frontend.language.levels.names.b2',
      nameLocaleStr: 'frontend.profile.onboarding.language_levels.b2',
      icon: level4Icon,
      colorIcon: level4ColorIcon
    }
  ],
  [
    'c1',
    {
      value: 'c1',
      levelShortName: 'C1',
      nameShortLocaleStr: 'frontend.language.levels.names.c1',
      nameLocaleStr: 'frontend.profile.onboarding.language_levels.c1',
      icon: level5Icon,
      colorIcon: level5ColorIcon
    }
  ]
]);

export function getLevelName(level: tLevel): string {
  return levelsData.get(level)?.levelShortName.toUpperCase() || '-';
}
