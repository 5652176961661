import cn from 'classnames';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import CheckIcon from '../CheckIcon';
import { tCheckboxOption } from './types';

interface ICheckboxItem {
  option: tCheckboxOption;
  onClick: (option: tCheckboxOption) => void;
  checked: boolean;
  className?: string;
}

const CheckboxItem: FC<ICheckboxItem> = ({ option, onClick, checked, className }) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleOnClick = useCallback(() => {
    setIsChecked((prev) => !prev);
    onClick(option);
  }, [onClick, option]);

  return (
    <SLabel className={cn(className)}>
      <SCheckBoxInput value={option.prefix} onClick={handleOnClick} tabIndex={0} />

      <SCheckbox
        className={cn('checkbox_item-checkbox', { checked: isChecked })}
        role="checkbox"
        aria-checked={isChecked}
        aria-label={(option.title ?? option.title)?.toString()}
        data-testid="students/components/LessonCatalog/CheckboxItem"
      >
        <CheckIcon />
      </SCheckbox>

      {option.title || option.prefix ? (
        <SCheckboxItemText className="checkbox-item-label">
          {option.prefix ? <strong>{option.prefix}</strong> : null}
          {option.title ? option.title : null}
        </SCheckboxItemText>
      ) : null}
    </SLabel>
  );
};

export default CheckboxItem;

const SCheckboxItemText = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--color-grey);
  margin: 0;
  cursor: pointer;

  & > strong {
    font-weight: 600;
    color: #3f3f48;
    text-transform: capitalize;
    margin-inline-end: 4px;
  }
`;

const SCheckbox = styled.div`
  width: 24px;
  height: 24px;
  margin-inline-end: 16px;
  border-radius: 6px;
  border: 2px solid #e6e6f0;
  transition: border 0.15s, background-color 0.15s;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    opacity: 0;
    transition: opacity 0.15s;
  }

  &:active {
    outline: 2px dashed var(--color-blue);
    outline-offset: 2px;
  }

  &.checked {
    border: 2px solid var(--color-blue);
    background-color: var(--color-blue);

    & > svg {
      opacity: 1;
    }
  }
`;

const SCheckBoxInput = styled.input.attrs({ type: 'checkbox' })`
  &:focus + ${SCheckbox} {
    outline: 2px dashed var(--color-blue);
    outline-offset: 2px;
  }
`;

const SLabel = styled.label`
  display: flex;
  align-items: center;
  margin-block-end: 16px;
  cursor: pointer;

  &:last-child {
    margin-block-end: 0;
  }

  & input {
    opacity: 0;
    height: 0;
    width: 0;
  }
`;
