import { css } from 'styled-components';

export const linkStyles = css`
  transition: opacity 0.1s ease;
  color: var(--heading-text-color);
  font-size: 1rem;
  border-radius: 4px;
  outline: none;

  &.active {
    color: var(--heading-text-color-1);
  }

  &:hover {
    color: var(--heading-text-color);
  }

  &:focus-visible {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 5px;
  }
`;
