import React from 'react';
import styled from 'styled-components';

const SETTINGS_ICON_GRADIENT = 'navigationSettingsGradient';

const SettingsIconSvg: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <SPath d="M19.4323 13.8169C19.4723 13.4969 19.5023 13.1769 19.5023 12.8369C19.5023 12.4969 19.4723 12.1769 19.4323 11.8569L21.5423 10.2069C21.7323 10.0569 21.7823 9.78691 21.6623 9.56691L19.6623 6.10691C19.5423 5.88691 19.2723 5.80691 19.0523 5.88691L16.5623 6.88691C16.0423 6.48691 15.4823 6.15691 14.8723 5.90691L14.4923 3.25691C14.4623 3.01691 14.2523 2.83691 14.0023 2.83691H10.0023C9.75228 2.83691 9.54228 3.01691 9.51228 3.25691L9.13228 5.90691C8.52228 6.15691 7.96228 6.49691 7.44228 6.88691L4.95228 5.88691C4.72228 5.79691 4.46228 5.88691 4.34228 6.10691L2.34228 9.56691C2.21228 9.78691 2.27228 10.0569 2.46228 10.2069L4.57228 11.8569C4.53228 12.1769 4.50228 12.5069 4.50228 12.8369C4.50228 13.1669 4.53228 13.4969 4.57228 13.8169L2.46228 15.4669C2.27228 15.6169 2.22228 15.8869 2.34228 16.1069L4.34228 19.5669C4.46228 19.7869 4.73228 19.8669 4.95228 19.7869L7.44228 18.7869C7.96228 19.1869 8.52228 19.5169 9.13228 19.7669L9.51228 22.4169C9.54228 22.6569 9.75228 22.8369 10.0023 22.8369H14.0023C14.2523 22.8369 14.4623 22.6569 14.4923 22.4169L14.8723 19.7669C15.4823 19.5169 16.0423 19.1769 16.5623 18.7869L19.0523 19.7869C19.2823 19.8769 19.5423 19.7869 19.6623 19.5669L21.6623 16.1069C21.7823 15.8869 21.7323 15.6169 21.5423 15.4669L19.4323 13.8169ZM12.0023 16.3369C10.0723 16.3369 8.50228 14.7669 8.50228 12.8369C8.50228 10.9069 10.0723 9.33691 12.0023 9.33691C13.9323 9.33691 15.5023 10.9069 15.5023 12.8369C15.5023 14.7669 13.9323 16.3369 12.0023 16.3369Z" />
      <SPath d="M19.4323 13.8169C19.4723 13.4969 19.5023 13.1769 19.5023 12.8369C19.5023 12.4969 19.4723 12.1769 19.4323 11.8569L21.5423 10.2069C21.7323 10.0569 21.7823 9.78691 21.6623 9.56691L19.6623 6.10691C19.5423 5.88691 19.2723 5.80691 19.0523 5.88691L16.5623 6.88691C16.0423 6.48691 15.4823 6.15691 14.8723 5.90691L14.4923 3.25691C14.4623 3.01691 14.2523 2.83691 14.0023 2.83691H10.0023C9.75228 2.83691 9.54228 3.01691 9.51228 3.25691L9.13228 5.90691C8.52228 6.15691 7.96228 6.49691 7.44228 6.88691L4.95228 5.88691C4.72228 5.79691 4.46228 5.88691 4.34228 6.10691L2.34228 9.56691C2.21228 9.78691 2.27228 10.0569 2.46228 10.2069L4.57228 11.8569C4.53228 12.1769 4.50228 12.5069 4.50228 12.8369C4.50228 13.1669 4.53228 13.4969 4.57228 13.8169L2.46228 15.4669C2.27228 15.6169 2.22228 15.8869 2.34228 16.1069L4.34228 19.5669C4.46228 19.7869 4.73228 19.8669 4.95228 19.7869L7.44228 18.7869C7.96228 19.1869 8.52228 19.5169 9.13228 19.7669L9.51228 22.4169C9.54228 22.6569 9.75228 22.8369 10.0023 22.8369H14.0023C14.2523 22.8369 14.4623 22.6569 14.4923 22.4169L14.8723 19.7669C15.4823 19.5169 16.0423 19.1769 16.5623 18.7869L19.0523 19.7869C19.2823 19.8769 19.5423 19.7869 19.6623 19.5669L21.6623 16.1069C21.7823 15.8869 21.7323 15.6169 21.5423 15.4669L19.4323 13.8169ZM12.0023 16.3369C10.0723 16.3369 8.50228 14.7669 8.50228 12.8369C8.50228 10.9069 10.0723 9.33691 12.0023 9.33691C13.9323 9.33691 15.5023 10.9069 15.5023 12.8369C15.5023 14.7669 13.9323 16.3369 12.0023 16.3369Z" />
      <defs>
        <linearGradient
          id={SETTINGS_ICON_GRADIENT}
          x1="14.8898"
          y1="26.0396"
          x2="6.65833"
          y2="1.98892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D6480B" />
          <stop offset="1" stopColor="#FF974D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SettingsIconSvg;

const SPath = styled.path`
  fill: #a5aac5;

  .active & {
    fill: url(#${SETTINGS_ICON_GRADIENT});
  }
`;
