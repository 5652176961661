import { ComponentType, FC } from 'react';
import LinkElem from 'students/views/shared/components/LinkElem';
import styled from 'styled-components';

function withLink<P extends Record<string, any>>(
  WrappedComponent: ComponentType<P>
): FC<P & { to: string }> {
  const hocComponent: FC<P & { to: string }> = ({ to, ...props }) => {
    return (
      <SLink to={to}>
        <WrappedComponent {...(props as unknown as P)} />
      </SLink>
    );
  };

  return hocComponent;
}

export default withLink;

const SLink = styled(LinkElem)`
  color: inherit;
  text-decoration: inherit;
  border-radius: 4px;
  display: block;

  &:hover {
    color: inherit;
    text-decoration: inherit;
  }

  &:visited {
    color: inherit;
    text-decoration: inherit;
  }

  &:focus {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 4px;
  }
`;
