import React from 'react';

interface IProps {
  className?: string;
}

const CloseIcon: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.28662 22L22.2866 6"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.2881 22L6.28809 6"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseIcon;
