import { RefObject, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  IStudentSupportLanguage,
  ILanguageInstance,
  profileActions,
  IStudentTargetLanguage,
  tCreateStudentTargetLanguage,
  tLevel
} from '@adeptlms/lingu-students-react-shared';
import { userSelectors } from 'students/stores/user';
import localeFlags from 'i18n/config/localeFlags';
import { useTypedSelector } from 'students/stores/_utils';
import { useDropdown } from './screens/hooks/useDropdown';
import { useRequestAvailableLanguages } from '../hooks';

interface ILanguageInfo {
  systemName?: string;
  flag?: string;
}
interface IReturnedObject {
  show: boolean;
  buttonRef: RefObject<HTMLButtonElement>;
  menuRef: RefObject<HTMLElement>;
  myTargetLanguageInfo: ILanguageInfo;
  mySupportLanguageInfo: ILanguageInfo;
  studentTargetLanguages?: IStudentTargetLanguage[];
  studentSupportLanguages?: IStudentSupportLanguage[];
  activeTargetLanguageId?: number | null;
  activeSupportLanguageId?: number | null;
  onShow: () => void;
  onHide: () => void;
  getTargetLanguageInfo: (languageId?: number) => ILanguageInfo;
  getSupportLanguageInfo: (languageId?: number) => ILanguageInfo;
  setTargetLanguages: (languageId: number, level: tLevel) => void;
  setSupportLanguages: (languageId: number) => void;
}

export const useLangDropdown = (): IReturnedObject => {
  const dispatch = useDispatch();
  useRequestAvailableLanguages();
  const profile = useTypedSelector(userSelectors.selectProfile);
  const targetLanguages = useTypedSelector(userSelectors.selectSortedTargetLangs);
  const supportedLanguages = useTypedSelector(userSelectors.selectSortedAllLangs);
  const studentTargetLanguages = useTypedSelector(
    userSelectors.selectProfileSortedTargetLanguages
  );
  const studentSupportLanguages = useTypedSelector(
    userSelectors.selectProfileSortedSupportLanguages
  );
  const { opened, openDropdown, closeDropdown } = useDropdown();
  const activeTargetLanguageId = profile?.activeStudentTargetLanguageId;
  const activeSupportLanguageId = profile?.nativeLanguageId;

  const menuRef = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const targetLanguage = studentTargetLanguages?.find(
    ({ id }: { id: number }) => id === activeTargetLanguageId
  );
  const supportLanguage = studentSupportLanguages?.find(
    ({ id }: { id: number }) => id === activeSupportLanguageId
  );

  const myTargetLanguageInfo = _getLanguageInfo(
    targetLanguage?.languageId,
    targetLanguages
  );
  const mySupportLanguageInfo = _getLanguageInfo(
    supportLanguage?.languageId,
    supportedLanguages
  );

  const getTargetLanguageInfo = useCallback(
    (languageId?: number): ILanguageInfo => _getLanguageInfo(languageId, targetLanguages),
    [targetLanguages]
  );
  const getSupportLanguageInfo = useCallback(
    (languageId?: number): ILanguageInfo =>
      _getLanguageInfo(languageId, supportedLanguages),
    [supportedLanguages]
  );

  const setTargetLanguages = async (languageId: number, level: tLevel) => {
    const userLanguage: tCreateStudentTargetLanguage = {
      active: true,
      languageId,
      level
    };
    dispatch(profileActions.selectStudentTargetLanguage(userLanguage));
  };

  const setSupportLanguages = async (languageId: number) => {
    await dispatch(
      profileActions.selectStudentSupportLanguage({
        mainLanguageId: languageId
      })
    );
  };

  return {
    show: opened,
    myTargetLanguageInfo,
    mySupportLanguageInfo,
    studentTargetLanguages,
    studentSupportLanguages,
    activeTargetLanguageId,
    activeSupportLanguageId,
    onShow: openDropdown,
    onHide: closeDropdown,
    getTargetLanguageInfo,
    getSupportLanguageInfo,
    setTargetLanguages,
    setSupportLanguages,
    menuRef,
    buttonRef
  };
};

function _getLanguageInfo(
  languageId?: number,
  langsArray?: ILanguageInstance[]
): ILanguageInfo {
  const language = langsArray?.find(({ id }) => id === languageId);
  const localeFlag = localeFlags.get(language?.code || '');
  return { systemName: language?.systemName, flag: localeFlag?.flag };
}
