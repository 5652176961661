import { IProfile } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { Translate } from 'i18n';
import { FC } from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import AvatarImage from '../../AvatarImage';

interface IProps {
  user: Partial<IProfile>;
  responsive?: boolean;
  mirror?: boolean;
  className?: string;
}

const UserAvatar: FC<IProps> = ({ user, responsive = true, mirror, className }) => {
  if (!user) return null;

  const { avatarURL } = user;

  return (
    <SAvatarWrapper className={cn({ 'ln-flex-row-reverse': mirror }, className)}>
      <SMemberArea className={cn({ 'ln-hidden md:ln-inline': responsive, mirror })}>
        <Translate str="frontend.profile.navigation.member" />
      </SMemberArea>

      <SAvatar src={avatarURL} alt="" />
    </SAvatarWrapper>
  );
};

export default UserAvatar;

const SAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SMemberArea = styled.span`
  margin-inline: 12px 8px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.625rem;
  color: var(--color-black);
`;

const SAvatar = styled(AvatarImage)`
  vertical-align: middle;
  height: 2rem;
  width: 2rem;

  ${customMediaQuery('tablet')} {
    height: 2.5rem;
    width: 2.5rem;
  }
  img {
    object-fit: contain;
  }
`;
