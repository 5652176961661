import { userSharedSelectors } from '@adeptlms/lingu-students-react-shared';
import { useTypedSelector } from 'students/stores/_utils';

function useSupportEqualsTarget(): boolean {
  const isEqual = useTypedSelector(
    userSharedSelectors.selectIfSupportEqualsTargetLanguage
  );

  return isEqual;
}

export default useSupportEqualsTarget;
