import React, { FC, useEffect, useRef } from 'react';
import cn from 'classnames';
import { tLevel } from '@adeptlms/lingu-students-react-shared';
import { t } from 'i18n';
import { SGrid, SCell, SImg, STitle, SSubtitle } from './styles';
import { ILanguageLevel } from './model';
import { availableLevels, levelsData } from '../../../levelsData';

export const LanguageLevel: FC<ILanguageLevel> = ({ pickedLevel, pick }) => {
  const firstItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (firstItemRef.current) {
      firstItemRef.current.focus();
    }
  }, [firstItemRef]);

  return (
    <SGrid>
      {availableLevels.map((level: tLevel, idx) => {
        const itemLevel = levelsData.get(level);
        const onPick = () => pick(`${itemLevel?.value}`);
        const isPicked = pickedLevel === itemLevel?.value;

        return (
          <SCell
            key={level}
            className={cn({ isPicked })}
            ref={idx === 0 ? firstItemRef : null}
            tabIndex={0}
            onClick={onPick}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                onPick();
              }
            }}
          >
            <SImg
              className={cn({ isPicked })}
              src={!isPicked ? itemLevel?.icon : itemLevel?.colorIcon}
            />
            <STitle>{itemLevel?.levelShortName}</STitle>
            <SSubtitle>{t(`${itemLevel?.nameShortLocaleStr}`)}</SSubtitle>
          </SCell>
        );
      })}
    </SGrid>
  );
};
