import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { Translate } from 'i18n';
import { FC, PropsWithChildren, RefObject } from 'react';
import {
  SMenu,
  SMenuList,
  SMenuTitle,
  SMenuWrapper,
  SPlus,
  SStartNewButton
} from './styled';

interface ILangDropdown {
  show: boolean;
  titleLocaleKey?: string;
  direction?: 'up' | 'down';
  menuRef?: RefObject<any>;
  openMenu?: () => void;
  btnTitleLocaleKey?: string;
  contentClassName?: string;
  renderChildren?: boolean;
}

export const LangDropdown: FC<PropsWithChildren<ILangDropdown>> = ({
  show,
  direction,
  children,
  openMenu,
  titleLocaleKey,
  btnTitleLocaleKey,
  menuRef,
  contentClassName,
  renderChildren
}) => {
  const yShift = direction === 'up' ? 'calc(-100% - 45px)' : 0;

  return (
    <AnimatePresence>
      {show ? (
        <SMenu
          className={cn(contentClassName)}
          transition={{ duration: 0.2 }}
          initial={{ opacity: 0, y: -20, x: '-50%' }}
          animate={{ opacity: 1, y: yShift, x: '-50%' }}
          exit={{ opacity: 0, y: -20, x: '-50%' }}
          ref={menuRef}
        >
          {renderChildren ? (
            children
          ) : (
            <>
              <SMenuWrapper>
                <SMenuTitle>
                  <Translate str={titleLocaleKey ?? ''} />:
                </SMenuTitle>

                <SMenuList>{children}</SMenuList>
              </SMenuWrapper>

              <SStartNewButton onClick={openMenu}>
                <SPlus /> <Translate str={btnTitleLocaleKey ?? ''} />
              </SStartNewButton>
            </>
          )}
        </SMenu>
      ) : null}
    </AnimatePresence>
  );
};
