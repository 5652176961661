import { motion } from 'framer-motion';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';
import {
  BUTTON_INNER_CLASS_NAME,
  styleInnerButton
} from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import styled from 'styled-components';

import { PickedIcon, PlusIcon } from '../../icons';

export const STargetLanguageButton = styled(ButtonGeneral)`
  display: flex;
  align-items: center;
  background: var(--target-language-button-background);
  border-radius: 100px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--heading-text-color, --color-white);
  padding: 10px 12px;
  border: none;
  outline: none;
  margin: 0 16px;
  cursor: pointer;

  &:focus > .${BUTTON_INNER_CLASS_NAME} {
    outline-offset: 10px;
  }
`;

export const SWrapper = styled.div`
  position: relative;
`;

export const STitleBlock = styled.span`
  margin-inline-end: 8px;
`;

export const SMenu = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--color-white);
  border: none;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: absolute;
  min-width: 217px;
  white-space: nowrap;
  top: 50px;
  transform: translateX(-50%);
  z-index: 3;
  inset-inline-start: 50%;
`;

export const SMenuTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: var(--color-black);
  margin-block-end: 8px;
`;

export const SMenuList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const SMenuListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--color-black);
  margin: 4px 0 0 0;
  padding: 2px 0;
  cursor: pointer;

  &.isActive {
    font-weight: 600;
  }
`;

export const SMenuListItemButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  transition: opacity 0.2s;

  &:focus-visible {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 5px;
    border-radius: 5px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const SStartNewButton = styled(ButtonGeneral)`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--color-blue);
  cursor: pointer;
  background: none;
  border-top: 1px solid #e6e6f0;
  padding: 1.1rem 1.4rem;

  ${styleInnerButton()} {
    justify-content: flex-start;
  }
`;

export const SMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-block: 12px;
  padding-inline: 16px;
  text-align: start;
`;

export const SPlus = styled(PlusIcon)`
  min-height: 22px;
  min-width: 22px;
  margin-inline-end: 8px;
`;

export const SPicked = styled(PickedIcon)`
  min-height: 16px;
  min-width: 16px;
  position: absolute;
  inset-inline-end: 0;
`;

export const SFlagForItem = styled.img`
  width: 23px;
  height: 17px;
  margin-inline-end: 8px;
`;

export const SFlagImgForButton = styled.img`
  width: 27px;
  height: 19px;
  border-radius: 4px;
`;
