import { useState } from 'react';

export const useDropdown = (): {
  opened: boolean;
  openDropdown: () => void;
  closeDropdown: () => void;
} => {
  const [opened, setIsOpened] = useState(false);
  const openDropdown = () => setIsOpened(true);
  const closeDropdown = () => setIsOpened(false);
  return {
    opened,
    openDropdown,
    closeDropdown
  };
};
