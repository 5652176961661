import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import avatarImg from 'students/views/shared/assets/avatar.svg';
import { customMediaQuery } from '../../styled';
import Picture from '../Picture';

interface IProps {
  src?: string | null;
  alt: string;
  className?: string;
}

const AvatarImage: React.FC<IProps> = ({ src, alt, className }) => (
  <SAvatar className={cn(className)}>
    <Picture lazy src={src || null} alt={alt} fallbackImage={avatarImg} />
  </SAvatar>
);

export default AvatarImage;

const SAvatar = styled.div`
  display: inline-block;
  background-color: #e6e6f0;
  overflow: hidden;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  min-width: 25px;

  ${customMediaQuery('tablet')} {
    height: 30px;
    width: 30px;
    min-width: 30px;
  }
`;
