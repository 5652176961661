import { FC, ReactElement, useCallback } from 'react';
import { useTypedDispatch, useTypedSelector } from 'students/stores/_utils';
import { modalActions, modalSelectors } from 'students/stores/modal';
import HeadlessModal from '../HeadlessModal';

interface IReduxModal {
  modalKey: string;
  preventClose?: boolean;
  backdropClassName?: string;
  containerClassName?: string;
  dialogClassName?: string;
  children: (close: () => void, context?: any) => ReactElement;
}

const ReduxModal: FC<IReduxModal> = ({
  modalKey,
  preventClose,
  backdropClassName,
  containerClassName,
  dialogClassName,
  children
}) => {
  const dispatch = useTypedDispatch();
  const modalToShow = useTypedSelector(modalSelectors.selectOpenedModalByKey(modalKey));

  const handleClose = useCallback(() => {
    dispatch(modalActions.close(modalKey));
  }, [dispatch, modalKey]);

  return (
    <HeadlessModal
      modalKey={modalKey}
      show={Boolean(modalToShow)}
      onHide={preventClose ? undefined : handleClose}
      backdropClassName={backdropClassName}
      containerClassName={containerClassName}
      dialogClassName={dialogClassName}
    >
      {children(handleClose, modalToShow?.context)}
    </HeadlessModal>
  );
};

export default ReduxModal;
