import { FC, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, authSelectors } from 'students/stores/auth';
import { tAppState } from 'students/stores/rootStore';
import { HeadlessModal } from 'students/views/shared/components/modal';
import { ReactLazyPreload } from 'students/views/shared/helpers';
import { setRedirectUrlToSession } from './helpers/sessionRedirect';
import OTPCheckFormEmail from './views/MailForm/OTPCheckEmailForm';
import OTPCheckFormSms from './views/SmsForm/OTPCheckSmsForm';
import StartView from './views/StartView';
import { OTPReferrer } from './views/types';

const SignInMailForm = ReactLazyPreload(() => import('./views/MailForm/SignInMailForm'));
const SignInSmsForm = ReactLazyPreload(() => import('./views/SmsForm/SignInSmsForm'));

const AuthModal: FC = () => {
  const { show } = useSelector<tAppState, ReturnType<typeof authSelectors.selectModal>>(
    authSelectors.selectModal
  );

  const dispatch = useDispatch();
  const body = useAuthModalBody(show);

  usePreloadedForms(show);

  const handleClose = () => {
    dispatch(authActions.close());
  };

  return (
    <HeadlessModal
      modalKey="auth-modal"
      show={show}
      onHide={handleClose}
      backdropClassName="ln-backdrop-blur-sm ln-bg-black/50"
      containerClassName="ln-items-center ln-justify-center ln-p-4"
      dialogClassName="ln-bg-white ln-rounded-2xl ln-min-w-80 ln-w-full sm:ln-w-[520px] sm:ln-max-w-[520px] ln-p-6 sm:ln-p-10"
    >
      {body}
    </HeadlessModal>
  );
};

export default AuthModal;

export function useAuthModalBody(show: boolean) {
  const [view, setView] = useState<
    'start' | 'mail_sign_in' | 'verify_otp_email' | 'sms_sign_in' | 'verify_otp_sms'
  >('start');
  const [referrer, setReferrer] = useState<OTPReferrer | null>(null);

  useEffect(() => {
    setRedirectUrlToSession();
  }, []);

  useEffect(() => {
    if (show) {
      setView('start');
    }
  }, [show]);

  const goToStartView = () => {
    setView('start');
  };

  const goToMailVariant = () => {
    setView('mail_sign_in');
  };

  const goToSMSVariant = () => {
    setView('sms_sign_in');
  };

  const showOTPCheckFormEmail = (_referrer: OTPReferrer) => {
    setReferrer(_referrer);
    setView('verify_otp_email');
  };

  const showOTPCheckFormSms = (_referrer: OTPReferrer) => {
    setReferrer(_referrer);
    setView('verify_otp_sms');
  };

  let body;
  switch (view) {
    case 'mail_sign_in': {
      body = (
        <SignInMailForm onChangeVariant={goToStartView} onNext={showOTPCheckFormEmail} />
      );
      break;
    }

    case 'sms_sign_in': {
      body = (
        <SignInSmsForm onChangeVariant={goToStartView} onNext={showOTPCheckFormSms} />
      );
      break;
    }

    case 'verify_otp_email': {
      body = <OTPCheckFormEmail onChangeVariant={goToMailVariant} referrer={referrer} />;
      break;
    }

    case 'verify_otp_sms': {
      body = <OTPCheckFormSms onChangeVariant={goToSMSVariant} referrer={referrer} />;
      break;
    }

    default: {
      body = (
        <StartView
          emailVariantClickHandler={goToMailVariant}
          smsVariantClickHandler={goToSMSVariant}
        />
      );
    }
  }
  return <Suspense fallback="">{body}</Suspense>;
}

let lazyFormsLoaded = false;
function usePreloadedForms(show: boolean) {
  useEffect(() => {
    if (!lazyFormsLoaded && show) {
      SignInMailForm.preload();
      lazyFormsLoaded = true;
    }
  }, [show]);
}
