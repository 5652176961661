import React from 'react';
import styled from 'styled-components';

const ASSIGNMENTS_ICON_GRADIENT = 'navigationAssignmentsGradient';

const AssignmentsIconSvg: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <SPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66406 6.28261C1.66406 4.84667 2.82812 3.68262 4.26406 3.68262H14.0432C15.4792 3.68262 16.6432 4.84668 16.6432 6.28262V19.8066C16.6432 21.2425 15.4792 22.4066 14.0432 22.4066H4.26406C2.82812 22.4066 1.66406 21.2425 1.66406 19.8066V6.28261ZM4.86966 8.36328C4.86966 7.811 5.31738 7.36328 5.86966 7.36328H12.4377C12.99 7.36328 13.4377 7.811 13.4377 8.36328C13.4377 8.91557 12.99 9.36328 12.4377 9.36328H5.86966C5.31738 9.36328 4.86966 8.91557 4.86966 8.36328ZM4.86966 12.855C4.86966 12.3027 5.31738 11.855 5.86966 11.855H10.7957C11.348 11.855 11.7957 12.3027 11.7957 12.855C11.7957 13.4073 11.348 13.855 10.7957 13.855H5.86966C5.31738 13.855 4.86966 13.4073 4.86966 12.855ZM4.86966 17.3468C4.86966 16.7945 5.31738 16.3468 5.86966 16.3468H7.51166C8.06395 16.3468 8.51166 16.7945 8.51166 17.3468C8.51166 17.899 8.06395 18.3468 7.51166 18.3468H5.86966C5.31738 18.3468 4.86966 17.899 4.86966 17.3468ZM18.5911 5.5555C18.5911 4.52141 19.4294 3.68311 20.4635 3.68311C21.4976 3.68311 22.3359 4.52141 22.3359 5.5555V7.92632H18.5911V5.5555ZM18.5911 18.5772V9.42632H22.3359V18.5772C22.3359 19.4427 22.0552 20.2848 21.5359 20.9772L20.4635 22.4071L19.3911 20.9772C18.8718 20.2848 18.5911 19.4427 18.5911 18.5772Z"
      />
      <defs>
        <linearGradient
          id={ASSIGNMENTS_ICON_GRADIENT}
          x1="12"
          y1="24.5186"
          x2="4.73695"
          y2="2.34846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5508E2" />
          <stop offset="1" stopColor="#8A61FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AssignmentsIconSvg;

const SPath = styled.path`
  fill: #a5aac5;

  .active & {
    fill: url(#${ASSIGNMENTS_ICON_GRADIENT});
  }
`;
