import styled from 'styled-components';

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-block-start: 17px;
  font-family: var(--lingu-text-font-family);

  @media (max-width: 500px) {
    display: block;
  }
`;

export const SCell = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 136px;
  width: 147px;
  background: rgba(0, 9, 40, 0.3);
  border-radius: 6px;
  margin-block-start: 10px;
  cursor: pointer;

  &:focus-visible {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 3px;
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 60px;
    margin-block-start: 4px;
    flex-direction: row;
    align-items: center;
  }

  &.isPicked {
    background: rgba(255, 255, 255, 0.1);

    & > div {
      opacity: 1;
    }
  }
`;

export const SImg = styled.img`
  margin-block-start: 25px;
  width: 42px;
  height: 42px;
  display: block;
  border: 0;

  &.isPicked {
    opacity: 0.5;
  }

  @media (max-width: 500px) {
    margin-block: 0;
    margin-inline: 18px 0;
  }
`;

export const SSubtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-white);
  opacity: 0.8;
`;

export const STitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: var(--color-white);
  position: relative;
  top: 4px;

  @media (max-width: 500px) {
    top: 0;
    margin-inline: 10px;
  }
`;
