import { preferencesActions, useIsPending } from '@adeptlms/lingu-students-react-shared';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'students/stores/_utils';
import { userSelectors } from 'students/stores/user';

export function useRequestAvailableLanguages(): void {
  const [hasBeenRequested, setHasBeenRequested] = useState(false);
  const dispatch = useDispatch();
  const hasAvailableLanguages =
    useTypedSelector(userSelectors.selectAllLangs).length !== 0;
  const isRequestingLangs = useIsPending([
    preferencesActions.getSupportedLanguages.typePrefix
  ]);

  useEffect(() => {
    if (
      !hasBeenRequested &&
      !hasAvailableLanguages &&
      !isRequestingLangs &&
      !document.location.href.includes('invitation')
    ) {
      dispatch(preferencesActions.getSupportedLanguages());
      setHasBeenRequested(true);
    }
  }, [dispatch, isRequestingLangs, hasAvailableLanguages, hasBeenRequested]);
}
