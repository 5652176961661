import { FC, useRef } from 'react';
import { withReduxModal } from 'students/views/shared/components/modal';
import { LanguagesPopup } from '../LanguagesPopup';
import { LanguagesList } from '../TargetLanguagesPopup/screens/LanguagesList';
import { useSupportLanguagesPopup } from './useSupportLanguagesPopup';

const SupportLanguagesPopup: FC = () => {
  const menuRef = useRef(null);
  const { handleSubmit, sortedAllLangs, pickLang, pickedLanIds } =
    useSupportLanguagesPopup();
  const steps = [
    {
      titleLocaleKey: `frontend.member.lang_dropdown.i_understand`,
      component: (
        <LanguagesList
          languages={sortedAllLangs}
          pickLang={pickLang}
          pickedLanIds={pickedLanIds}
          withSearch
          withAdditionalInfo
        />
      ),
      handleSubmit
    }
  ];
  return <LanguagesPopup menuRef={menuRef} steps={steps} modalKey="supportLangSelect" />;
};

export default withReduxModal('supportLangSelect', {
  backdropClassName: 'ln-backdrop-blur-sm ln-bg-black/50',
  containerClassName: 'ln-items-end sm:ln-items-center ln-justify-center sm:ln-p-4',
  dialogClassName:
    'ln-bg-grey-n-4 ln-rounded-2xl ln-w-full sm:ln-max-w-96 ln-relative ln-shadow-card-light'
})(SupportLanguagesPopup);
