import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { tLevel } from '@adeptlms/lingu-students-react-shared';
import { modalActions } from 'students/stores/modal';
import { useOutsideClick } from 'students/views/shared/hooks';
import { useLangDropdown } from '../../useLangDropdown';

interface IReturnedObject {
  menuRef: React.RefObject<HTMLElement>;
  buttonRef: React.RefObject<HTMLButtonElement>;
  onShow: () => void;
  openMenu: () => ReturnType<typeof modalActions.open>;
  myTargetLanguageInfo: ReturnType<typeof useLangDropdown>['myTargetLanguageInfo'];
  setTargetLanguages: ReturnType<typeof useLangDropdown>['setTargetLanguages'];
  show: boolean;
  studentTargetLanguagesInfo?: {
    languageId: number;
    level: tLevel;
    isActive: boolean;
    systemName?: string;
    flag?: string;
  }[];
}

export function useTargetDropdown(): IReturnedObject {
  const dispatch = useDispatch();
  const openMenu = () => dispatch(modalActions.open('langSelect'));
  const {
    onShow,
    onHide,
    buttonRef,
    menuRef,
    myTargetLanguageInfo,
    show,
    studentTargetLanguages,
    activeTargetLanguageId,
    setTargetLanguages,
    getTargetLanguageInfo
  } = useLangDropdown();
  useOutsideClick(menuRef, onHide, [buttonRef]);

  const studentTargetLanguagesInfo = useMemo(
    () =>
      studentTargetLanguages?.map(({ languageId, level, id }) => {
        const isActive = activeTargetLanguageId === id;
        const { systemName, flag } = getTargetLanguageInfo(languageId);
        return {
          languageId,
          level,
          isActive,
          systemName,
          flag
        };
      }),
    [activeTargetLanguageId, getTargetLanguageInfo, studentTargetLanguages]
  );

  return {
    menuRef,
    buttonRef,
    onShow,
    openMenu,
    myTargetLanguageInfo,
    setTargetLanguages,
    show,
    studentTargetLanguagesInfo
  };
}
