import React from 'react';

interface IProps {
  className?: string;
}

const BackIcon: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 7L4 14M4 14L11 21M4 14H23.5" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default BackIcon;
