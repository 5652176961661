import { FC, useRef } from 'react';
import {
  tWithReduxModalProps,
  withReduxModal
} from 'students/views/shared/components/modal';
import { LanguagesPopup } from '../LanguagesPopup';
import { LanguageLevel } from './screens/LanguageLevel';
import { LanguagesList } from './screens/LanguagesList';
import { useTargetLangPopup } from './useTargetLangPopup';

const TargetLanguagesPopup: FC<tWithReduxModalProps> = ({ context }) => {
  const menuRef = useRef(null);
  const {
    languages,
    pickedLanIds,
    pickedLevel,
    pickLang,
    pickLevel,
    handleSubmit,
    targetLangLevelEditSubmit
  } = useTargetLangPopup();

  const steps = [
    {
      titleLocaleKey: `frontend.member.lang_popup.i_want_to_learn`,
      component: (
        <LanguagesList
          languages={languages}
          pickLang={pickLang}
          pickedLanIds={pickedLanIds}
          hasFlag
        />
      ),
      handleSubmit
    },
    {
      titleLocaleKey: `frontend.member.lang_dropdown.select_language_level`,
      component: <LanguageLevel pick={pickLevel} pickedLevel={pickedLevel} />,
      handleSubmit
    }
  ];

  if (context?.languageId !== undefined) {
    return (
      <LanguagesPopup
        menuRef={menuRef}
        steps={[
          {
            titleLocaleKey: `frontend.member.lang_dropdown.select_language_level`,
            component: <LanguageLevel pick={pickLevel} pickedLevel={pickedLevel} />,
            handleSubmit: targetLangLevelEditSubmit(context.languageId)
          }
        ]}
        modalKey="langSelect"
      />
    );
  }

  return <LanguagesPopup menuRef={menuRef} steps={steps} modalKey="langSelect" />;
};

export default withReduxModal('langSelect', {
  backdropClassName: 'ln-backdrop-blur-sm ln-bg-black/50',
  containerClassName: 'ln-items-end sm:ln-items-center ln-justify-center sm:ln-p-4',
  dialogClassName:
    'ln-bg-grey-n-4 ln-rounded-2xl ln-w-full sm:ln-max-w-96 ln-relative ln-shadow-card-light'
})(TargetLanguagesPopup);
