import cn from 'classnames';
import { I18nContext, Translate, locales } from 'i18n';
import { FC, useCallback, useContext, useState } from 'react';
import AngleArrowIcon from 'students/views/shared/assets/icons/angle_arrow.svg';
import ArrowIcon from 'students/views/shared/assets/icons/light_back_arrow_icon.svg';
import { HeadlessModal } from 'students/views/shared/components/modal';
import styled from 'styled-components';
import ButtonGeneral from '../ButtonGeneral';
import { styleInnerButton } from '../ButtonGeneral/ButtonGeneral';
import CheckIcon from '../CheckIcon';
import UrlIcon from '../UrlIcon';

interface ILocaleOption {
  lKey: string;
  flagSrc: string;
  nativeName: string;
}

interface IProps {
  className?: string;
}

const LanguageListSwitcher: FC<IProps> = ({ className }) => {
  const { locale, setLocale } = useContext(I18nContext);
  const [show, setShow] = useState(false);

  const currentOption = locales.find(({ lKey }) => lKey === locale);

  const openSelect = useCallback(() => {
    setShow(true);
  }, []);

  const closeSelect = useCallback(() => {
    setShow(false);
  }, []);

  const handleLocaleChange = useCallback(
    (locale: ILocaleOption) => {
      if (locale && 'lKey' in locale) {
        setLocale(locale.lKey);

        closeSelect();
      }
    },
    [closeSelect, setLocale]
  );

  return (
    <>
      <SButton variant="link" onClick={openSelect} className={cn(className)}>
        {currentOption ? <Translate str={currentOption.labelKey} /> : ''}
      </SButton>

      <HeadlessModal
        modalKey="language-list-switcher-modal"
        show={show}
        containerClassName="ln-w-full ln-bg-white ln-py-6 ln-px-4"
        dialogClassName="ln-w-full"
      >
        <SMenuBody>
          <BackButton variant="link" onClick={closeSelect} className="ln-self-start">
            <UrlIcon url={ArrowIcon} color="#2d2d3a" height="2rem" width="2rem" />
          </BackButton>

          <SList>
            {locales.map((locale: ILocaleOption) => (
              <SListItem key={locale.lKey} onClick={() => handleLocaleChange(locale)}>
                <SFlag url={locale.flagSrc} height="28px" width="28px" />

                <SLang
                  className={cn({
                    active: !!currentOption && currentOption.lKey === locale.lKey
                  })}
                >
                  {locale.nativeName}
                </SLang>

                {!!currentOption && currentOption.lKey === locale.lKey ? (
                  <CheckIcon color="#00a5d7" />
                ) : null}
              </SListItem>
            ))}
          </SList>
        </SMenuBody>
      </HeadlessModal>
    </>
  );
};

export default LanguageListSwitcher;

const SMenuBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const SButton = styled(ButtonGeneral)`
  color: var(--color-black);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  position: relative;
  background-color: var(--color-white);

  ${styleInnerButton()} {
    padding: 0.5rem 1rem;

    &::after {
      content: url(${AngleArrowIcon});
      display: block;
      height: 12px;
      width: 12px;
      position: absolute;
      top: 9px;
      inset-inline-start: 1px;
    }
  }

  ${styleInnerButton('focus')},
  &:active {
    text-decoration: none;
  }
`;

const SList = styled.ul`
  margin: 1rem 0;
  padding: 0 1rem;
  list-style: none;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

const SListItem = styled.li`
  margin: 0 0 1rem;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const SFlag = styled(UrlIcon)`
  margin-inline-end: 0.75rem;
`;

const SLang = styled.p`
  color: var(--color-black);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  position: relative;
  padding: 0;
  margin: 0;

  &.active {
    font-weight: 600;
  }
`;

const BackButton = styled(ButtonGeneral)`
  background-color: transparent;
  padding-top: env(safe-area-inset-top);

  ${styleInnerButton()} {
    padding: 0.375rem 0.75rem;
  }
`;
