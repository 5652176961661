const SESSION_REDIRECT_KEY = 'redirect_url';

const setRedirectUrlToSession = () => {
  const paramsRedirectUrl = new URLSearchParams(window.location.search).get(
    SESSION_REDIRECT_KEY
  );

  if (paramsRedirectUrl) {
    window.sessionStorage.setItem(SESSION_REDIRECT_KEY, paramsRedirectUrl);
  }
};

const getRedirectUrlFromSession = () => {
  const redirectUrl =
    window.sessionStorage.getItem(SESSION_REDIRECT_KEY) ||
    new URLSearchParams(window.location.search).get(SESSION_REDIRECT_KEY);

  window.sessionStorage.removeItem(SESSION_REDIRECT_KEY);

  return redirectUrl;
};

const clearRedirectUrlFromSession = () => {
  window.sessionStorage.removeItem(SESSION_REDIRECT_KEY);
};

export {
  setRedirectUrlToSession,
  getRedirectUrlFromSession,
  clearRedirectUrlFromSession
};
