import { IProfile } from '@adeptlms/lingu-students-react-shared';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { authActions } from 'students/stores/auth';
import { userSelectors } from 'students/stores/user';
import lightLogo from 'students/views/shared/assets/lingu-light-logo.svg';

import { useTypedDispatch, useTypedSelector } from 'students/stores/_utils';
import useShowDesktopNavigation from './useShowDesktopNavigation';

interface IUseNavBar {
  logoSrc: string;
  userProfile: IProfile | null;
  isNavigationVisible: boolean;
  handleLogOut: () => void;
}

export default function useNavBar(): IUseNavBar {
  const history = useHistory();
  const isBigScreen = useShowDesktopNavigation();
  const dispatch = useTypedDispatch();
  const userProfile = useTypedSelector(userSelectors.selectProfile);

  const logoSrc = lightLogo;

  const handleLogOut = useCallback(async () => {
    await dispatch(authActions.logOut());
    history.push('/');
  }, [dispatch, history]);

  return { logoSrc, userProfile, isNavigationVisible: isBigScreen, handleLogOut };
}
