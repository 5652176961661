import cn from 'classnames';
import { Translate } from 'i18n';
import { CSSProperties, FC } from 'react';
import { navigationDesktopBreakPoint } from 'students/views/pages/Member/components/theme/breakpoints';
import ButtonGeneral, {
  BUTTON_INNER_CLASS_NAME,
  styleInnerButton
} from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import { PlusIcon } from '../../../../icons';
import { SupportLanguagesPopup } from '../../../SupportLanguagesPopup';
import { TargetLanguagesPopup } from '../../../TargetLanguagesPopup';
import { getLevelName } from '../../../levelsData';
import { LangDropdown } from '../../LangDropdown';
import {
  SFlagForItem,
  SFlagImgForButton,
  SMenuListItem,
  SMenuListItemButton,
  SPicked
} from '../../styled';
import { useTargetDropdown } from '../TargetDropdown/useTargetDropdown';
import { useSupportDropdown } from './useSupportDropdown';

interface IProps {
  className?: string;
  buttonColor?: string;
  buttonBorderColor?: string;
  withoutLangPopup?: boolean;
  withIndicateError?: boolean;
  direction?: 'up' | 'down';
  contentClassName?: string;
}

export const HeaderSupportDropdown: FC<IProps> = ({
  className,
  buttonColor,
  buttonBorderColor,
  withoutLangPopup,
  withIndicateError,
  direction,
  contentClassName
}) => {
  const {
    show,
    currentSupportLanguageNativeName,
    menuRef,
    onShow,
    openMenu: openSupportMenu,
    setSupportLanguages,
    studentSupportLanguagesInfo,
    languagesAreEqual
  } = useSupportDropdown();
  const {
    openMenu: openTargetMenu,
    studentTargetLanguagesInfo,
    setTargetLanguages
  } = useTargetDropdown();

  const activeSupportLangFlag = studentSupportLanguagesInfo?.find(
    (lang) => lang.isActive
  );

  const activeLearningNow = studentTargetLanguagesInfo?.find((lang) => lang.isActive);

  const buttonContentColor = buttonColor || 'var(--heading-text-color)';
  const triggerBorderColor = buttonBorderColor || 'var(--support-language-button-border)';

  return (
    <SWrapper className={cn(className)}>
      <SDropdownOpenButton
        onClick={onShow}
        style={
          {
            '--buttonColor': buttonContentColor,
            '--buttonBorderColor': triggerBorderColor
          } as CSSProperties
        }
        className={cn('DropdownOpenButton', {
          hasError: withIndicateError && languagesAreEqual
        })}
      >
        <>
          <Translate str="frontend.member.lang_dropdown.support_languages" />

          <FlagsWrapper>
            <>
              {activeLearningNow ? (
                <SFlagImgForButtonLearning
                  src={activeLearningNow.flag}
                  alt={
                    activeLearningNow.systemName?.trim()
                      ? `Flag of ${activeLearningNow.systemName?.trim()}`
                      : ''
                  }
                />
              ) : (
                <span className="ln-min-w-0 ln-truncate">
                  {currentSupportLanguageNativeName}
                </span>
              )}
              {activeSupportLangFlag ? (
                <SFlagImgForButtonInterface
                  src={activeSupportLangFlag.flag}
                  alt={
                    activeSupportLangFlag.systemName?.trim()
                      ? `Flag of ${activeSupportLangFlag.systemName?.trim()}`
                      : ''
                  }
                />
              ) : (
                <span className="ln-min-w-0 ln-truncate">
                  {currentSupportLanguageNativeName}
                </span>
              )}
            </>
          </FlagsWrapper>
        </>
      </SDropdownOpenButton>

      <LangDropdown
        show={show}
        direction={direction ?? 'down'}
        menuRef={menuRef}
        renderChildren
        contentClassName={contentClassName}
      >
        {/* Support Language */}
        <SMenuWrapper>
          <SMenuTitle>
            <Translate str="frontend.member.lang_dropdown.support_languages" />
          </SMenuTitle>

          <SMenuList>
            {studentSupportLanguagesInfo?.map(
              ({ languageId, isActive, flag, systemName }): JSX.Element => {
                const onClick = () => setSupportLanguages(languageId);
                return (
                  <SMenuListItem
                    className={cn({ isActive })}
                    onClick={onClick}
                    key={languageId}
                  >
                    <SMenuListItemButton title={systemName?.trim()}>
                      <SFlagForItem src={flag} />
                      <span className="ln-min-w-0 ln-truncate ln-pe-4">
                        {systemName?.trim()}
                      </span>
                      {isActive && <SPicked />}
                    </SMenuListItemButton>
                  </SMenuListItem>
                );
              }
            )}
          </SMenuList>
        </SMenuWrapper>

        <SStartNewButton onClick={openSupportMenu}>
          <SPlus /> <Translate str="frontend.member.lang_dropdown.add_another_language" />
        </SStartNewButton>

        <SDivider />

        {/* Target Language */}
        <SMenuWrapper>
          <SMenuTitle>
            <Translate str="frontend.member.lang_dropdown.menu_title" />
          </SMenuTitle>

          <SMenuList>
            {studentTargetLanguagesInfo?.map(
              ({ level, languageId, isActive, flag, systemName }): JSX.Element | null => {
                if (!systemName && !flag) {
                  return null;
                }

                const onClick = () => setTargetLanguages(languageId, level);
                return (
                  <SMenuListItem
                    className={cn({ isActive })}
                    onClick={onClick}
                    key={languageId}
                  >
                    <SMenuListItemButton
                      title={`${systemName?.trim()} · ${getLevelName(level)}`}
                    >
                      <SFlagForItem src={flag} />
                      <span className="ln-min-w-0 ln-truncate ln-pe-4">{`${systemName?.trim()} · ${getLevelName(
                        level
                      )}`}</span>
                      {isActive && <SPicked />}
                    </SMenuListItemButton>
                  </SMenuListItem>
                );
              }
            )}
          </SMenuList>
        </SMenuWrapper>

        <SStartNewButton onClick={openTargetMenu}>
          <SPlus /> <Translate str="frontend.member.lang_dropdown.start_new" />
        </SStartNewButton>
      </LangDropdown>

      {!withoutLangPopup ? (
        <>
          <TargetLanguagesPopup />
          <SupportLanguagesPopup />
        </>
      ) : null}
    </SWrapper>
  );
};
const SFlagImgForButtonInterface = styled(SFlagImgForButton)`
  @media (min-width: ${navigationDesktopBreakPoint}px) {
    position: absolute;
    outline: 3px solid #010615 !important;
    bottom: 0;
    left: 0;
  }
`;

const SFlagImgForButtonLearning = styled(SFlagImgForButton)`
  display: none;
  position: absolute;
  right: 0;
  top: 0;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    display: block;
  }
`;

const FlagsWrapper = styled.div`
  @media (min-width: ${navigationDesktopBreakPoint}px) {
    position: relative;
    width: 46px;
    height: 32px;
  }
`;

const SWrapper = styled.div`
  position: relative;
`;

const SDropdownOpenButton = styled(ButtonGeneral)`
  background: transparent;
  width: 100%;
  text-align: start;
  padding: 0 !important;
  margin: 0 !important;
  display: block;

  border: none;
  outline: none;
  cursor: pointer;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 12px;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  & .button-inner {
    width: 100%;
    display: flex;
    height: 40px;
    width: 40px;
    padding: 10px 6px !important;
    gap: 4px;
    align-items: center;
    justify-content: space-between !important;

    ${customMediaQuery('desktop')} {
      height: 52px;
      width: 100%;
      padding: 10px 12px !important;
    }

    span {
      display: none;
      font-size: 14px;

      ${customMediaQuery('desktop')} {
        display: inline-block;
      }
    }
  }

  &:focus > .${BUTTON_INNER_CLASS_NAME} {
    outline-offset: 10px;
  }

  &.hasError {
    border-radius: 30px;
    border: 2px solid #ff5858;

    ${styleInnerButton()} {
      color: rgba(255, 88, 88, 0.9);
      font-weight: 600;
    }
  }

  ${styleInnerButton()} {
    color: var(--color-white);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    padding-block: 10px;
    padding-inline: 12px;
  }
`;

const SMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-block: 12px 0;
  padding-inline: 12px;

  ${customMediaQuery('desktop')} {
    padding-inline: 20px;
  }
`;

const SMenuList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const SMenuTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: var(--color-black);
  margin-block-end: 8px;
  text-align: start;
`;

const SDivider = styled.div`
  height: 2px;
  width: 100%;
  background: #ececec;
  margin-block: 4px;
  margin-inline: 0;
`;

const SStartNewButton = styled(ButtonGeneral)`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--color-blue);
  cursor: pointer;
  background: none;
  padding: 1.1rem 1.3rem;

  ${styleInnerButton()} {
    justify-content: flex-start;
  }
`;

const SPlus = styled(PlusIcon)`
  min-height: 22px;
  min-width: 22px;
  margin-inline-end: 8px;
`;
