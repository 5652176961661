import { FC, PropsWithChildren, useEffect } from 'react';

import AudioContext from './AudioContext';
import { useAudioManager } from './hooks';

const AudioProvider: FC<PropsWithChildren> = ({ children }) => {
  const actions = useAudioManager();

  useEffect(() => {
    return () => {
      actions.reset();
    };
  }, [actions]);

  return <AudioContext.Provider value={{ ...actions }}>{children}</AudioContext.Provider>;
};

export default AudioProvider;
