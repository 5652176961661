import { useBreakpointNumber } from 'students/views/shared/hooks';

import { navigationBreakPoint } from '../theme/breakpoints';

function useShowDesktopNavigation(): boolean {
  const isBigScreen = useBreakpointNumber(navigationBreakPoint);

  return isBigScreen;
}

export default useShowDesktopNavigation;
