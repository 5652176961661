import { ITaskAudioManagerItem, tHowlAudioPlayerStatus, tTaskAudioTags } from './types';
import HowlAudioPlayer from './HowlAudioPlayer';

export interface ITaskAudioRepositoryCollectionItem {
  id: string | number;
  src: string | string[];
  tags: tTaskAudioTags;
  player: HowlAudioPlayer;
  caching: boolean;
}

export type tTaskAudioRepositoryCollection = ITaskAudioRepositoryCollectionItem[];

export default class TaskAudioRepository {
  private collection: tTaskAudioRepositoryCollection = [];

  constructor(items: ITaskAudioManagerItem[] | null) {
    items &&
      items.forEach((item) => {
        this.add(item);
      });
  }

  add(item: ITaskAudioManagerItem): void {
    try {
      this.collection.push(createItemEntity(item));
    } catch (e: any) {
      item.onError && item.onError(e?.message);
    }
  }

  delete(id: string | number): void {
    this.collection = this.collection.filter((item) => item.id !== id);
  }

  reset(): void {
    this.collection = [];
  }

  findAll(): tTaskAudioRepositoryCollection {
    return this.collection;
  }

  findByStatus(status: tHowlAudioPlayerStatus): tTaskAudioRepositoryCollection {
    return this.collection.filter((item) => status === item.player.status);
  }

  findOneById(id: string | number): ITaskAudioRepositoryCollectionItem | null {
    const item = this.collection.filter((item) => id === item.id);

    return item.length > 0 ? item[0] : null;
  }

  findOneUncachedById(id: string | number): ITaskAudioRepositoryCollectionItem | null {
    const item = this.collection.filter((item) => id === item.id && !item.caching);

    return item.length > 0 ? item[0] : null;
  }

  findOneBySrc(src: string): ITaskAudioRepositoryCollectionItem | null {
    const item = this.collection.filter((item) => src === item.src);

    return item.length > 0 ? item[0] : null;
  }

  findByTag(tag: string): tTaskAudioRepositoryCollection {
    return this.collection.filter((item) => {
      return item.tags.filter((currentTag) => tag === currentTag).length;
    });
  }
}

function createItemEntity(
  item: ITaskAudioManagerItem
): ITaskAudioRepositoryCollectionItem {
  return {
    id: item.id,
    tags: item.tags || [],
    src: item.src,
    player: new HowlAudioPlayer(item),
    caching: !!item.caching
  };
}
