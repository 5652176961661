import cn from 'classnames';
import { Translate } from 'i18n';
import { FC, useCallback } from 'react';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';
import { styleInnerButton } from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import styled from 'styled-components';
import { useAuthModalOpen } from '../hooks';

interface IProps {
  onClick?: () => void;
  className?: string;
  primary?: boolean;
}

const SignInButton: FC<IProps> = ({ className, onClick, primary }) => {
  const openAuthModal = useAuthModalOpen();

  const signInClickHandler = useCallback(() => {
    openAuthModal();
    if (onClick) {
      onClick();
    }
  }, [onClick, openAuthModal]);

  return (
    <SButton
      variant={primary ? 'primary' : 'light'}
      className={cn(className)}
      onClick={signInClickHandler}
    >
      <Translate str="frontend.auth.sign_in" />
    </SButton>
  );
};

export default SignInButton;

const SButton = styled(ButtonGeneral)`
  margin: 0 0.5rem;
  border-radius: 2.25rem;
  font-weight: 600;
  font-size: 0.75rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
  border: none;

  ${styleInnerButton()} {
    padding: 0.75rem 1.75rem;
  }

  @media (min-width: ${({ theme }) => theme.linguBptMd}) {
    font-size: 1.125rem;
  }
`;
