// Top navigation bar for desktop
import { motion } from 'framer-motion';
import { Translate } from 'i18n';
import { FC } from 'react';
import ProfileMenu from 'students/views/pages/Member/components/navigation/components/ProfileMenu';
import LinkElem from 'students/views/shared/components/LinkElem';
import { HeaderSupportDropdown } from 'students/views/shared/components/languagesSelection/LangDropdown';
import userVariables from 'students/views/shared/styled/userVariables';
import styled from 'styled-components';
import { useBreakPoint } from 'students/views/shared/hooks';
import { navigationDesktopBreakPoint } from '../theme/breakpoints';
import ChatIcon from './components/ChatIcon';
import { INavigationItem, navigationMediaQuery } from './config';
import useNavBarItems from './hooks/useNavBarItems';
import { IconLink, SRoteLink } from './styles';
import useNavBar from './useNavBar';
import GoToTeacherArea from './GoToTeacherArea';

type tProps = {
  mobileVariant?: 'small' | 'none';
};
const SUPPORT_LANG_DROPDOWN_CONTENT_CLASS_NAME = 'memberSupportDropdownContent';
const NavBar: FC<tProps> = ({ mobileVariant }) => {
  const { logoSrc, userProfile, handleLogOut, isNavigationVisible } = useNavBar();
  const navBarItems = useNavBarItems();
  const isDesktop = useBreakPoint('xl', true);

  if (!isNavigationVisible && mobileVariant === 'none') {
    return null;
  }

  const checkNavLinkIsActive =
    (item: INavigationItem) =>
    (match: Record<string, any> | null, location: Record<string, any>): boolean => {
      if (!match) {
        return false;
      }

      if (match.isExact && match.path.slice(1) === item.path) {
        return true;
      }

      if (location?.state?.isSubRoute && match.path.slice(1) === item.path) {
        return true;
      }

      return match.isExact;
    };

  const renderNavBarItem = (item: INavigationItem) => {
    const link = item.isDynamic ? (
      <motion.div
        key={`${item.name}_motion`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <SRoteLink
          isActive={checkNavLinkIsActive(item)}
          activeClassName="active"
          to={item.path}
        >
          {item.icon && <IconLink $src={item.icon} />}
          <Translate scope="frontend.mobile_tabs" str={item.name} />
        </SRoteLink>
      </motion.div>
    ) : (
      <SRoteLink
        isActive={checkNavLinkIsActive(item)}
        activeClassName="active"
        key={`${item.name}_link`}
        to={item.path}
      >
        {item.icon && <IconLink $src={item.icon} />}
        <Translate scope="frontend.mobile_tabs" str={item.name} />
      </SRoteLink>
    );

    return item.badgeCount ? (
      <SBadgeWrapper key={`${item.name}_badge`} $badgeCount={item.badgeCount}>
        {link}
      </SBadgeWrapper>
    ) : (
      link
    );
  };

  return (
    <SNavBarWrapper>
      <SNavBar>
        {isNavigationVisible ? (
          <>
            <SNavigation>
              <SLogoLink to="/">
                <SLogo src={logoSrc} alt="Lingu" />
              </SLogoLink>

              {navBarItems.map(renderNavBarItem)}

              <ChatIcon />
            </SNavigation>

            {userProfile?.isTeacher && !isDesktop ? <GoToTeacherArea /> : null}
          </>
        ) : null}

        <SProfileContainer>
          <SHeaderSupportDropdown
            direction="up"
            contentClassName={SUPPORT_LANG_DROPDOWN_CONTENT_CLASS_NAME}
            withIndicateError
          />

          <ProfileMenu userProfile={userProfile} handleLogOut={handleLogOut} />
        </SProfileContainer>
      </SNavBar>
    </SNavBarWrapper>
  );
};

export default NavBar;

const SNavBarWrapper = styled.div`
  height: 72px;
  width: 100%;
  padding-top: env(safe-area-inset-top);

  ${navigationMediaQuery} {
    height: 80px;
  }

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    height: auto;
    width: auto;
    position: sticky;
  }
  display: flex;
`;

const SNavBar = styled.header`
  padding: 1rem;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 44px;

  a {
    text-decoration: none;
  }

  ${navigationMediaQuery} {
    height: 80px;
    padding: 0 2rem;
    background: var(--navigation-navbar-background-color);
    justify-content: space-between;
  }

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    position: sticky;
    top: 0;
    flex-direction: column;
    align-items: stretch;
    flex-shrink: 0;
    max-height: 100vh;
    height: initial;
    width: ${userVariables.sidebarNavigationWidth};
    padding: 40px 20px;
    background: var(--navigation-navbar-background-color);
    justify-content: space-between;
  }
`;

const SProfileContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-self: end;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    display: block;
    margin-block-start: auto;

    & > *:first-child {
      margin-block-end: 16px;
    }
  }
`;

const SNavigation = styled.nav`
  ${navigationMediaQuery} {
    display: flex;
    align-items: center;
  }

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    display: block;
  }
`;

const SHeaderSupportDropdown = styled(HeaderSupportDropdown)`
  & .${SUPPORT_LANG_DROPDOWN_CONTENT_CLASS_NAME} {
    inset-inline-start: auto;
    inset-inline-end: -50px;
    transform: translateX(0) !important;

    @media (min-width: ${navigationDesktopBreakPoint}px) {
      inset-inline-start: 0 !important;
      transform: translateX(0) !important;
      top: auto !important;
      bottom: calc(100% + 4px) !important;
    }
  }
`;

const SBadgeWrapper = styled.span<{ $badgeCount?: number }>`
  position: relative;

  ${({ $badgeCount }) =>
    $badgeCount
      ? `
    &, a {
      color: var(--meeting-time-color) !important;
    }
    
    &:after {
      content: '${$badgeCount}';
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      background: var(--meeting-time-color);
      border-radius: 50%;
      color: var(--navbar-badge-color);
      font-weight: 600;
      font-size: 0.625rem;
      position: absolute;
      top: -5px;
      inset-inline-end: -5px;
    }
  `
      : ''}
`;

const SLogoLink = styled(LinkElem)`
  display: flex;
  flex-shrink: 0;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    margin-block-end: 32px;
  }

  &:focus {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 5px;
    border-radius: 15px;
  }
`;

const SLogo = styled.img`
  width: 4rem;
  height: auto;
  margin-inline-end: 0.5rem;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    margin-inline-end: 0;
    width: 6.75rem;
  }
`;
