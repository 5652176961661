// Mobile and Tablet bottom navigation
import React, { FC } from 'react';
import styled from 'styled-components';
import useTabBarItems from './hooks/useTabBarItems';
import TabBarItem from './components/TabBarItem';
import { navigationMediaQuery, INavigationItem } from './config';
import useShowDesktopNavigation from './useShowDesktopNavigation';
import MobileChatIcon from './components/MobileChatIcon';

const TabBar: FC = () => {
  const isBigScreen = useShowDesktopNavigation();
  const visibleTabBarItems = useTabBarItems();

  if (isBigScreen) return null;

  return (
    <STabBarWrapper>
      <STabBar>
        {visibleTabBarItems.map((item: INavigationItem) => (
          <TabBarItem key={item.name} item={item} />
        ))}

        <MobileChatIcon />
      </STabBar>
    </STabBarWrapper>
  );
};

export default TabBar;

const STabBar = styled.nav`
  position: fixed;
  display: flex;
  width: 100vw;
  height: calc(70px + env(safe-area-inset-bottom) / 2);
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
  background-color: var(--navigation-tab-bar-background-color);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
  bottom: 0;
  left: 0;
  z-index: 100;

  a {
    flex: 1;
    text-decoration: none;
    color: rgb(174, 174, 184);
  }

  div {
    flex: 1;
  }

  ${navigationMediaQuery} {
    display: none;
  }
`;

const STabBarWrapper = styled.div`
  width: 100%;
  position: relative;
`;
