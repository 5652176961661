import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { navigationDesktopBreakPoint } from '../theme/breakpoints';

export const linkStyles = `
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 8px 0;
  padding: 10px;
  height: 48px;
  border-radius: 14px;

  transition: all 0.1s ease;
  color: var(--heading-text-color);
  color: var(--color-white);
  font-size: 12px;
  white-space: nowrap;
  line-height: 1;
  outline: none;

  transition: background 0.2s ease, color 0.2s ease;

  i {
    display: none;
    color: #A3A6AB;
  }

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    font-size: 18px;

    i {
      display: inline-block;
    }
  }

  
  &.active {
    font-weight: 700;
    background: #e1ff6a;
    color: var(--button-primary-text, #0b1420);
    pointer-events: none;

    i {
      opacity: 1;
      color: currentColor;
    }
  }

  &:hover {
    color: var(--heading-text-color);
    background: rgba(255,255,255,0.1);
  }

  &:focus-visible {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 5px;
  }
`;

export const IconLink = styled.i<{ $src?: string }>`
  height: 28px;
  width: 28px;

  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: currentcolor;
  background-size: contain;
  -webkit-mask-image: url(${({ $src }) => $src});
  mask-image: url(${({ $src }) => $src});
`;

export const SRoteLink = styled(NavLink)`
  ${linkStyles}
`;
export const SLink = styled.a`
  ${linkStyles}
`;
