import React from 'react';
import styled from 'styled-components';

const MEETINGS_ICON_GRADIENT = 'navigationMeetingsGradient';

const MeetingsIconSvg: React.FC = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <SPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1999 2.44482C6.53716 2.44482 5.9999 2.98208 5.9999 3.64482V4.84482H4.7999C3.47442 4.84482 2.3999 5.91934 2.3999 7.24482V19.2448C2.3999 20.5703 3.47442 21.6448 4.7999 21.6448H19.1999C20.5254 21.6448 21.5999 20.5703 21.5999 19.2448V7.24482C21.5999 5.91934 20.5254 4.84482 19.1999 4.84482H17.9999V3.64482C17.9999 2.98208 17.4626 2.44482 16.7999 2.44482C16.1372 2.44482 15.5999 2.98208 15.5999 3.64482V4.84482H8.3999V3.64482C8.3999 2.98208 7.86264 2.44482 7.1999 2.44482ZM7.1999 8.44482C6.53716 8.44482 5.9999 8.98208 5.9999 9.64482C5.9999 10.3076 6.53716 10.8448 7.1999 10.8448H16.7999C17.4626 10.8448 17.9999 10.3076 17.9999 9.64482C17.9999 8.98208 17.4626 8.44482 16.7999 8.44482H7.1999Z"
      />
      <defs>
        <linearGradient
          id={MEETINGS_ICON_GRADIENT}
          x1="17.7264"
          y1="24.5844"
          x2="4.93599"
          y2="0.922111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CD096A" />
          <stop offset="1" stopColor="#FF4AA4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MeetingsIconSvg;

const SPath = styled.path`
  fill: rgb(174, 174, 184);

  .active & {
    fill: url(#${MEETINGS_ICON_GRADIENT});
  }
`;
