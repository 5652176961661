import { Translate } from 'i18n';
import React, { useCallback } from 'react';
import { AUTH_URL } from 'students/constants';
import emailIconPNG from 'students/views/shared/assets/providers/email/email-logo.png';
import emailIcon from 'students/views/shared/assets/providers/email/email-logo.svg';
import emailIconWebp from 'students/views/shared/assets/providers/email/email-logo.webp';
import smsIconPNG from 'students/views/shared/assets/providers/sms/sms-logo.png';
import smsIcon from 'students/views/shared/assets/providers/sms/sms-logo.svg';
import smsIconWebp from 'students/views/shared/assets/providers/sms/sms-logo.webp';
import styled from 'styled-components';
import { SocialButton } from '../components';
import { socials } from '../helpers';
import { SFaddedText, SHeader } from '../styled';

interface IProps {
  emailVariantClickHandler: () => void;
  smsVariantClickHandler: () => void;
}

const StartView: React.FC<IProps> = ({
  emailVariantClickHandler,
  smsVariantClickHandler
}) => {
  const handleSocialClick = useCallback((link?: string) => {
    if (!link) return;
    const popupURL = `${AUTH_URL ?? ''}${link}?popup=true`;
    window.open(popupURL);
  }, []);

  return (
    <>
      <SHeader className="onboarding-startview-header">
        <Translate str="frontend.auth.join_lingu" />
      </SHeader>

      <SVariants className="onboarding-startview-variants">
        <SOtpVariant
          onClick={emailVariantClickHandler}
          fullNameLocaleKey="frontend.auth.continue_with_email"
          logo={emailIcon}
          webpLogo={emailIconWebp}
          fallbackLogo={emailIconPNG}
        />

        <SOtpVariant
          onClick={smsVariantClickHandler}
          fullNameLocaleKey="frontend.auth.continue_with_phone"
          logo={smsIcon}
          webpLogo={smsIconWebp}
          fallbackLogo={smsIconPNG}
        />

        <SFaddedText>
          <Translate str="frontend.auth.or" />
        </SFaddedText>

        {socials.map(({ providerName, link, webpLogo, logo, fallbackLogo }) => (
          <SocialButton
            key={providerName}
            link={link}
            onClick={handleSocialClick}
            providerName={providerName}
            logo={logo}
            webpLogo={webpLogo}
            fallbackLogo={fallbackLogo}
          />
        ))}
      </SVariants>
    </>
  );
};

export default StartView;

const SVariants = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;

  button {
    margin: 0.5rem 0;

    &:hover {
      text-decoration: none;
    }
  }
`;

const SOtpVariant = styled(SocialButton)`
  margin-block-start: 0.5rem;
`;
