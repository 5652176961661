import { Member } from '@adeptlms/lingu-students-react-shared';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  homeItem,
  assignmentsItem,
  // catalogItem,
  learningMaterialsItem,
  // meetingsItem,
  liveClassesItem,
  boardsItem,
  INavigationItem
} from '../config';

function useNavBarItems(): Array<INavigationItem> {
  const meetingsStore = useSelector(Member.selectors.selectMeetingsStore);
  const boardsStore = useSelector(Member.selectors.selectBoardsStore);

  return useMemo(() => {
    const result = [homeItem];

    if (meetingsStore.meetings.length) {
      // TEMP
      // result.push(meetingsItem); remove after LiveClasses is fully operational
      result.push(liveClassesItem);
    }

    result.push(assignmentsItem);

    // result.push(catalogItem);
    result.push(learningMaterialsItem);

    result.push({
      ...boardsItem,
      badgeCount: boardsStore.boardsCount ?? 0
    });

    return result;
  }, [meetingsStore.meetings, boardsStore.boardsCount]);
}

export default useNavBarItems;
