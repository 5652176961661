import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { customMediaQuery } from 'students/views/shared/styled';

interface INoResultsMessage {
  text: string | ReactNode;
  subText?: string | ReactNode;
  className?: string;
}

const NoResultsMessage: FC<INoResultsMessage> = ({ text, subText, className }) => (
  <SWrapper className={cn(className)}>
    <SMessage>{text}</SMessage>

    <SSubMessage>{subText}</SSubMessage>
  </SWrapper>
);

export default NoResultsMessage;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SMessage = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: var(--heading-text-color);
  margin: 0 0 14px;
  padding: 0;
  text-align: center;

  ${customMediaQuery('tablet')} {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
`;

const SSubMessage = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--paragraph-text-color);
  margin: 0;
  padding: 0;
  text-align: center;

  ${customMediaQuery('tablet')} {
    font-size: 1rem;
  }
`;
