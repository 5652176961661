/* eslint-disable no-console */
import React from 'react';
import { IAudioManagerReturnedObject } from './models';
import { TaskAudioManager } from './classes';

const AudioContext = React.createContext<IAudioManagerReturnedObject>({
  manager: new TaskAudioManager([]),
  add: () => console.log('add handler should be defined'),
  addUnique: () => console.log('addUnique handler should be defined'),
  reset: () => console.log('reset handler should be defined'),
  unregister: () => console.log('unregisterAndDelete handler should be defined'),
  unregisterUncached: () => console.log('unregisterUncached handler should be defined'),
  getAll: () => {
    console.log('getAll handler should be defined');
    return [];
  },
  stop: () => console.log('stop handler should be defined'),
  unsubscribe: () => console.log('unsubscribe handler should be defined'),
  playById: () => console.log('playById handler should be defined'),
  getById: () => {
    console.log('getById handler should be defined');
    return null;
  },
  visibilityPlay: () => console.log('visibilityPlay handler should be defined'),
  getByTag: () => {
    console.log('getByTag handler should be defined');
    return [];
  },
  unload: () => console.log('unload handler should be defined'),
  visibilityPause: () => console.log('visibilityPause handler should be defined'),
  addTagToMute: () => console.log('addTagToMute should be defined'),
  removeTagFromMute: () => console.log('removeTagFromMute shold be defined')
});

export default AudioContext;
