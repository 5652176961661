import cn from 'classnames';
import { Translate } from 'i18n';
import { FC, PropsWithChildren } from 'react';

interface IProps {
  textLocaleKey?: string;
  className?: string;
}
const Alert: FC<PropsWithChildren<IProps>> = ({ textLocaleKey, className, children }) => {
  return (
    <div
      className={cn(
        'ln-rounded ln-py-4 ln-px-6 ln-bg-red-100 ln-text-red-800 ln-w-full',
        className
      )}
    >
      {textLocaleKey ? <Translate str={textLocaleKey} /> : children}
    </div>
  );
};

export default Alert;
