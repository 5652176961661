import cn from 'classnames';
import { Translate } from 'i18n';
import noScroll from 'no-scroll';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import {
  AngleArrowRightIcon,
  CloseLightIcon
} from 'students/views/shared/components/icons';
import { HeadlessModal } from 'students/views/shared/components/modal';
import { DEFAULT_FONT_SIZE } from 'students/views/shared/providers/Preferences/Config';
import {
  getPreference,
  setDocumentFontSize,
  setPreference
} from 'students/views/shared/providers/Preferences/helpers';
import { Preferences } from 'students/views/shared/providers/Preferences/models';
import styled from 'styled-components';
import { MAX_SIZE, MIN_SIZE, SIZE_STEP } from './constants';

interface IMobileFontSizeScreen {
  children?: (onClick: () => void) => ReactElement;
  className?: string;
}

const MobileFontSizeScreen: FC<IMobileFontSizeScreen> = ({ children, className }) => {
  const [screenVisible, setScreenVisible] = useState<boolean>(false);
  const [fontSize, setFontSize] = useState<string>(
    getPreference(Preferences.FontSize, DEFAULT_FONT_SIZE)
  );

  const switchFontSize = useCallback(
    (newFontSize: any) => {
      setFontSize(setPreference(Preferences.FontSize, newFontSize));
      setDocumentFontSize(newFontSize);
    },
    [setFontSize]
  );

  const openScreen = useCallback(() => {
    noScroll.on();
    setScreenVisible(true);
  }, [setScreenVisible]);

  const closeScreen = useCallback(() => {
    noScroll.off();
    setScreenVisible(false);
  }, [setScreenVisible]);

  useEffect(() => {
    return () => {
      noScroll.off();
    };
  }, []);

  return (
    <div className={cn(className)}>
      {children ? (
        children(openScreen)
      ) : (
        <SButton onClick={openScreen}>
          <SArrowIcon height="10px" width="10px" color="#ffffff" />
        </SButton>
      )}

      <HeadlessModal
        modalKey="mobile-font-size-switcher-modal"
        show={screenVisible}
        backdropClassName="!ln-z-[10000]"
        containerClassName="!ln-z-[10000] ln-bg-page-gradient"
        dialogClassName="ln-w-screen ln-p-6 ln-flex ln-flex-col ln-items-center ln-justify-center"
      >
        <SCloseButton onClick={closeScreen}>
          <SCloseIcon height="20px" width="20px" color="#ffffff" />
        </SCloseButton>

        <STip>
          <Translate str="frontend.member.settings.pages.sound_and_visual.text_example" />
        </STip>

        <SSlider
          min={MIN_SIZE}
          max={MAX_SIZE}
          step={SIZE_STEP}
          dots
          value={parseInt(fontSize, 10)}
          onChange={switchFontSize}
        />
      </HeadlessModal>
    </div>
  );
};

export default MobileFontSizeScreen;

const SButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100px;
  height: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SArrowIcon = styled(AngleArrowRightIcon)`
  background-color: var(--common-font-size-mobile-arrow-background);
`;

const SCloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  position: absolute;
  top: calc(20px + env(safe-area-inset-top) / 2);
  left: 20px;
`;

const SCloseIcon = styled(CloseLightIcon)`
  background-color: var(--heading-text-color);
`;

const STip = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  text-align: center;
  margin: 0 0 60px;
  padding: 0;
  color: var(--paragraph-text-color);
  width: 90%;
`;

const SSlider = styled(Slider)`
  width: calc(100% - 30px) !important;
  padding: 20px 0;

  &:before {
    content: 'A';
    position: absolute;
    top: -26px;
    left: 0;
    font-size: 1rem;
    font-weight: 700;
    color: var(--heading-text-color);
  }

  &:after {
    content: 'A';
    position: absolute;
    top: -44px;
    right: 0;
    font-size: 30px;
    font-weight: 700;
    color: var(--heading-text-color);
  }

  .rc-slider-rail {
    height: 10px;
    background: var(--common-font-size-mobile-range-rail-background);
    box-shadow: inset 0 2px 0 rgba(0, 9, 40, 0.1);
  }

  .rc-slider-track {
    height: 10px;
    background: transparent;
  }

  .rc-slider-handle {
    background: var(--common-font-size-range-handle-background);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: none;
    height: 28px;
    width: 28px;
    margin-block-start: -10px;
  }

  .rc-slider-step {
    height: 6px;

    & > span:first-child {
      left: 6px !important;
    }

    & > span:last-child {
      left: calc(100% - 4px) !important;
    }
  }

  .rc-slider-dot {
    background: var(--common-font-size-mobile-range-dot-background);
    border: none;
    height: 6px;
    width: 6px;
  }
`;
