import styled from 'styled-components';
import cn from 'classnames';
import { customMediaQuery } from 'students/views/shared/styled';
import ButtonGeneral, {
  styleInnerButton
} from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';

interface IMemberButtonProps {
  variant: 'primary' | 'secondary' | 'secondary-dark' | 'outline' | 'danger';
  [key: string]: any;
}

const SMemberButton = styled(ButtonGeneral).attrs((props) => ({
  className: cn(`member-${props.variant}`)
}))<IMemberButtonProps>`
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;

  & > div,
  & > div > span {
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.member-secondary {
    background: var(--common-secondary-member-button-background);
    color: var(--heading-text-color);
    border: 2px solid transparent;

    &:hover {
      background: var(--common-secondary-member-button-hover-background);
    }
  }

  &.member-secondary-dark {
    background: var(--common-secondary-dark-member-button-background);
    color: var(--heading-text-color);
    border: 2px solid transparent;

    &:hover {
      background: var(--common-secondary-dark-member-button-hover-background);
    }
  }

  &.member-outline {
    background: rgba(255, 255, 255, 0.1);
    color: var(--color-blue-400);
    border: 2px solid var(--color-blue);
  }

  &.member-danger {
    background: var(--color-red);
    color: var(--color-white);
    border: 2px solid transparent;

    &:hover {
      background: #fc7070;
    }
  }

  &.member-primary {
    background: var(--button-primary-bg);
    color: var(--button-primary-text);
    border: 2px solid transparent;

    &:hover {
      background: var(--color-blue-800);
    }
  }

  ${styleInnerButton()} {
    padding: 0.75rem 1.75rem;
  }

  ${customMediaQuery('tablet')} {
    font-size: 1.25rem;
    min-width: 275px;
  }
`;

export default SMemberButton;
