import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import languagesSvg from 'students/views/shared/assets/icons/language.svg';
import ButtonGeneral, {
  styleInnerButton
} from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { LangDropdown } from '../../LangDropdown';

import { useSupportDropdown } from './useSupportDropdown';
import { SFlagForItem, SMenuListItem, SPicked } from '../../styled';
import { SupportLanguagesPopup } from '../../../SupportLanguagesPopup';

interface IProps {
  className?: string;
  buttonColor?: string;
  buttonBorderColor?: string;
  withoutLangPopup?: boolean;
  withIndicateError?: boolean;
  direction?: 'up' | 'down';
}

export const SupportDropdown: FC<IProps> = ({
  className,
  buttonColor,
  buttonBorderColor,
  withoutLangPopup,
  withIndicateError,
  direction
}) => {
  const {
    show,
    currentSupportLanguageNativeName,
    menuRef,
    onShow,
    openMenu,
    setSupportLanguages,
    studentSupportLanguagesInfo,
    languagesAreEqual
  } = useSupportDropdown();

  const buttonContentColor = buttonColor || 'var(--heading-text-color)';
  const triggerBorderColor = buttonBorderColor || 'var(--support-language-button-border)';
  return (
    <SWrapper className={cn(className)}>
      <SDropdownOpenButton
        onClick={onShow}
        style={
          {
            '--buttonColor': buttonContentColor,
            '--buttonBorderColor': triggerBorderColor
          } as CSSProperties
        }
        className={cn('DropdownOpenButton', {
          hasError: withIndicateError && languagesAreEqual
        })}
      >
        <SLanguagesImg
          url={languagesSvg}
          height="24px"
          width="24px"
          color={buttonContentColor}
        />
        {currentSupportLanguageNativeName}
      </SDropdownOpenButton>

      <LangDropdown
        show={show}
        openMenu={openMenu}
        direction={direction ?? 'up'}
        titleLocaleKey="frontend.member.lang_dropdown.support_languages"
        btnTitleLocaleKey="frontend.member.lang_dropdown.add_another_language"
        menuRef={menuRef}
      >
        {studentSupportLanguagesInfo?.map(
          ({ languageId, isActive, flag, systemName }) => {
            const onClick = () => setSupportLanguages(languageId);
            return (
              <SMenuListItem
                className={cn({ isActive })}
                onClick={onClick}
                key={languageId}
              >
                <SFlagForItem src={flag} />
                {systemName?.trim()}
                {isActive && <SPicked />}
              </SMenuListItem>
            );
          }
        )}
      </LangDropdown>
      {!withoutLangPopup ? <SupportLanguagesPopup /> : null}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  position: relative;
`;

const SDropdownOpenButton = styled(ButtonGeneral)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  margin: 10px 0;
  height: 48px;
  background: transparent;
  color: var(--buttonColor);
  font-weight: 600;
  position: relative;
  transition: border 0.3s;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 1);
  }

  &.hasError {
    border-radius: 30px;
    border: 2px solid #ff5858;

    ${styleInnerButton()} {
      color: rgba(255, 88, 88, 0.9);
      font-weight: 600;

      & > div {
        background: #ff5858;
      }
    }
  }

  ${styleInnerButton()} {
    padding: 13.5px 33.5px;
  }

  & > nav {
    margin-inline-start: 11rem !important;
  }
`;

const SLanguagesImg = styled(UrlIcon)`
  margin-inline-end: 4px;
`;
