import { Member } from '@adeptlms/lingu-students-react-shared';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { tabBarItems, liveClassesItem, INavigationItem } from '../config';

function useTabBarItems(): Array<INavigationItem> {
  const meetingsStore = useSelector(Member.selectors.selectMeetingsStore);

  return useMemo(() => {
    const result = [...tabBarItems];

    if (meetingsStore.meetings.length) {
      result.splice(1, 0, liveClassesItem);
    }

    return result;
  }, [meetingsStore.meetings]);
}

export default useTabBarItems;
