import React, { FC, useCallback, useRef } from 'react';
import cn from 'classnames';
import styled from 'styled-components';

interface IToggleSwitch {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  className?: string;
}

const ToggleSwitch: FC<IToggleSwitch> = ({ enabled, onChange, className }) => {
  const toggleSwitch = useCallback(() => {
    onChange(!enabled);
  }, [onChange, enabled]);

  const toggleRef = useRef<HTMLDivElement | null>(null);

  const handleKeyPress = useCallback(
    (e: any) => {
      /* Enter or Space */
      if (e.keyCode === 32 || e.keyCode === 13) {
        toggleSwitch();
      }
    },
    [toggleSwitch]
  );

  return (
    <SWrapper
      ref={toggleRef}
      tabIndex={0}
      className={cn(className, { enabled })}
      onClick={toggleSwitch}
      onKeyDown={handleKeyPress}
    >
      <SBall />
    </SWrapper>
  );
};

export default ToggleSwitch;

const SWrapper = styled.div`
  border-radius: 100px;
  box-shadow: var(--toggle-switch-box-shadow);
  position: relative;
  width: 54px;
  min-width: 54px;
  height: 30px;
  cursor: pointer;
  background: var(--toggle-switch-inactive-background);
  overflow: hidden;

  &:focus {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 2px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(95.74deg, #00bce8 0.64%, #0082b2 99.16%), #0082b2;
    box-shadow: var(--toggle-switch-box-shadow);
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }

  & > div {
    transform: translate(0, 0);
  }

  &.enabled {
    & > div {
      transform: translate(25px, 0);
    }

    &:before {
      opacity: 1;
    }
  }
`;

const SBall = styled.div`
  background: linear-gradient(
    180deg,
    var(--color-white) 0%,
    rgba(255, 255, 255, 0.88) 100%
  );
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: transform 0.3s ease-out;
`;
