import React, { FC } from 'react';
import cn from 'classnames';

interface ICheckIcon {
  color?: string;
  className?: string;
}

const CheckIcon: FC<ICheckIcon> = ({ color, className }) => (
  <svg
    className={cn(className)}
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill={color || 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.91538 9.52961L0.690373 5.40517C0.436542 5.15738 0.436542 4.75562 0.690373 4.50781L1.60959 3.61044C1.86342 3.36263 2.275 3.36263 2.52883 3.61044L5.375 6.38884L11.4712 0.437795C11.725 0.190006 12.1366 0.190006 12.3904 0.437795L13.3096 1.33516C13.5635 1.58295 13.5635 1.98471 13.3096 2.23252L5.83462 9.52964C5.58076 9.77743 5.16921 9.77742 4.91538 9.52961Z"
      fill="inherit"
    />
  </svg>
);

export default CheckIcon;
