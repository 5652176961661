import React from 'react';
import styled from 'styled-components';

const MESSAGES_ICON_GRADIENT = 'navigationMessagesGradient';

const MessagesIconSvg: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <SPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1 21.0063C17.6815 21.0063 22.2063 17.0472 22.2063 12.1633C22.2063 7.27946 17.6815 3.32031 12.1 3.32031C6.5184 3.32031 1.99365 7.27946 1.99365 12.1633C1.99365 13.8749 2.54935 15.4728 3.51148 16.8266C3.61586 16.9735 3.64044 17.1638 3.56946 17.3295L2.39073 20.0798C2.22785 20.4599 2.57042 20.8621 2.97157 20.7619L6.75775 19.8153C6.87624 19.7857 7.00123 19.8009 7.11057 19.8553C8.58257 20.5879 10.2854 21.0063 12.1 21.0063ZM7.6924 13.5714C8.47005 13.5714 9.10047 12.941 9.10047 12.1633C9.10047 11.3857 8.47005 10.7553 7.6924 10.7553C6.91475 10.7553 6.28433 11.3857 6.28433 12.1633C6.28433 12.941 6.91475 13.5714 7.6924 13.5714ZM13.7214 12.1633C13.7214 12.941 13.091 13.5714 12.3133 13.5714C11.5356 13.5714 10.9052 12.941 10.9052 12.1633C10.9052 11.3857 11.5356 10.7553 12.3133 10.7553C13.091 10.7553 13.7214 11.3857 13.7214 12.1633ZM16.9345 13.5714C17.7121 13.5714 18.3425 12.941 18.3425 12.1633C18.3425 11.3857 17.7121 10.7553 16.9345 10.7553C16.1568 10.7553 15.5264 11.3857 15.5264 12.1633C15.5264 12.941 16.1568 13.5714 16.9345 13.5714Z"
      />
      <defs>
        <linearGradient
          id={MESSAGES_ICON_GRADIENT}
          x1="15.1023"
          y1="23.4786"
          x2="8.72256"
          y2="1.57769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9925" />
          <stop offset="1" stopColor="#FFBC1B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MessagesIconSvg;

const SPath = styled.path`
  fill: rgb(174, 174, 184);

  .active & {
    fill: url(#${MESSAGES_ICON_GRADIENT});
  }
`;
