import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  profileActions,
  ILanguageInstance,
  tCreateStudentTargetLanguage
} from '@adeptlms/lingu-students-react-shared';
import { userSelectors } from 'students/stores/user';
import { useTypedSelector } from 'students/stores/_utils';
import { useSelect } from '../LanguagesPopup';
import { getTypedLevel } from './getTypedLevel';
import { useRequestAvailableLanguages } from '../hooks';

interface IReturnObject {
  languages: ILanguageInstance[];
  pickedLanIds: string[];
  pickedLevel: string;
  pickLang: (id: string | number) => void;
  pickLevel: (id: string | number) => void;
  onResetLevel: () => void;
  onReset: () => void;
  targetLangLevelEditSubmit: (langInstanceId: number) => () => void;
  sortedAllLangs: ILanguageInstance[];
  handleSubmit: () => void;
}

export const useTargetLangPopup = (): IReturnObject => {
  const dispatch = useDispatch();
  useRequestAvailableLanguages();

  const languages = useTypedSelector(userSelectors.selectSortedTargetLangs);
  const sortedAllLangs = useTypedSelector(userSelectors.selectSortedAllLangs);
  const defaultPickedIds = languages[0] ? [`${languages[0].id}`] : [];
  const {
    pick: pickLang,
    onReset,
    pickedIds: pickedLanIds
  } = useSelect({
    defaultPickedIds,
    multiple: false
  });
  const {
    pick: pickLevel,
    onReset: onResetLevel,
    pickedIds: [pickedLevel]
  } = useSelect({
    multiple: false
  });
  const handleSubmit = useCallback(() => {
    const userLanguage: tCreateStudentTargetLanguage = {
      active: true,
      languageId: +pickedLanIds[0],
      level: getTypedLevel(pickedLevel)
    };
    dispatch(profileActions.selectStudentTargetLanguage(userLanguage));
  }, [dispatch, pickedLanIds, pickedLevel]);

  const targetLangLevelEditSubmit = useCallback(
    (languageInstanceId: number) => () => {
      const userLanguage = {
        active: true,
        languageId: languageInstanceId,
        level: getTypedLevel(pickedLevel)
      };
      dispatch(profileActions.selectStudentTargetLanguage(userLanguage));
    },
    [dispatch, pickedLevel]
  );

  return {
    languages,
    pickedLanIds,
    pickedLevel,
    pickLang,
    pickLevel,
    onResetLevel,
    onReset,
    targetLangLevelEditSubmit,
    sortedAllLangs,
    handleSubmit
  };
};
