import React from 'react';
import styled from 'styled-components';

const CATALOG_ICON_GRADIENT = 'navigationCatalogGradient';

const CatalogIconSvg: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <SPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.86768 6.08389C3.86768 4.64795 5.03174 3.48389 6.46768 3.48389H17.5323C18.9682 3.48389 20.1323 4.64795 20.1323 6.08389V16.8783C20.1323 17.9351 19.2756 18.7918 18.2188 18.7918H12.7175L12.716 18.7916H6.87933C6.27289 18.7916 5.78128 19.2832 5.78128 19.8896C5.78128 20.4961 6.27289 20.9877 6.87933 20.9877H19.1757C19.7041 20.9877 20.1324 21.416 20.1324 21.9444C20.1324 22.4728 19.7041 22.9012 19.1757 22.9012H6.87933C5.22063 22.9012 3.87514 21.5602 3.86782 19.9032L3.86768 19.9034V6.08389ZM7.21617 9.70246C7.21617 9.17406 7.64452 8.74571 8.17291 8.74571H15.8269C16.3552 8.74571 16.7836 9.17406 16.7836 9.70246C16.7836 10.2308 16.3552 10.6592 15.8269 10.6592H8.17291C7.64452 10.6592 7.21617 10.2308 7.21617 9.70246Z"
      />
      <defs>
        <linearGradient
          id={CATALOG_ICON_GRADIENT}
          x1="14.4159"
          y1="26.0108"
          x2="5.46261"
          y2="3.48389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9925" />
          <stop offset="1" stopColor="#FFBC1B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CatalogIconSvg;

const SPath = styled.path`
  fill: #a5aac5;

  .active & {
    fill: url(#${CATALOG_ICON_GRADIENT});
  }
`;
