import React from 'react';
import styled from 'styled-components';

const BOARDS_ICON_GRADIENT = 'navigationBoardsGradient';

const BoardsIconSvg: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <SPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1146 2.89111C13.6787 2.89111 12.5146 4.05517 12.5146 5.49111V7.94962C12.5146 9.38556 13.6787 10.5496 15.1146 10.5496H18.6023C20.0382 10.5496 21.2023 9.38556 21.2023 7.94962V5.49111C21.2023 4.05517 20.0382 2.89111 18.6023 2.89111H15.1146ZM5.39785 14.8721C3.96191 14.8721 2.79785 16.0361 2.79785 17.4721V18.9015C2.79785 20.3374 3.96191 21.5015 5.39785 21.5015H8.88546C10.3214 21.5015 11.4855 20.3374 11.4855 18.9015V17.4721C11.4855 16.0361 10.3214 14.8721 8.88546 14.8721H5.39785ZM12.5146 14.3847C12.5146 12.9487 13.6787 11.7847 15.1146 11.7847H18.6023C20.0382 11.7847 21.2023 12.9487 21.2023 14.3847V18.9014C21.2023 20.3373 20.0382 21.5014 18.6023 21.5014H15.1146C13.6787 21.5014 12.5146 20.3373 12.5146 18.9014V14.3847ZM5.39785 2.89111C3.96191 2.89111 2.79785 4.05517 2.79785 5.49111V11.0369C2.79785 12.4729 3.96191 13.6369 5.39785 13.6369H8.88546C10.3214 13.6369 11.4855 12.4729 11.4855 11.0369V5.49111C11.4855 4.05517 10.3214 2.89111 8.88546 2.89111H5.39785Z"
      />
      <SPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1146 2.89111C13.6787 2.89111 12.5146 4.05517 12.5146 5.49111V7.94962C12.5146 9.38556 13.6787 10.5496 15.1146 10.5496H18.6023C20.0382 10.5496 21.2023 9.38556 21.2023 7.94962V5.49111C21.2023 4.05517 20.0382 2.89111 18.6023 2.89111H15.1146ZM5.39785 14.8721C3.96191 14.8721 2.79785 16.0361 2.79785 17.4721V18.9015C2.79785 20.3374 3.96191 21.5015 5.39785 21.5015H8.88546C10.3214 21.5015 11.4855 20.3374 11.4855 18.9015V17.4721C11.4855 16.0361 10.3214 14.8721 8.88546 14.8721H5.39785ZM12.5146 14.3847C12.5146 12.9487 13.6787 11.7847 15.1146 11.7847H18.6023C20.0382 11.7847 21.2023 12.9487 21.2023 14.3847V18.9014C21.2023 20.3373 20.0382 21.5014 18.6023 21.5014H15.1146C13.6787 21.5014 12.5146 20.3373 12.5146 18.9014V14.3847ZM5.39785 2.89111C3.96191 2.89111 2.79785 4.05517 2.79785 5.49111V11.0369C2.79785 12.4729 3.96191 13.6369 5.39785 13.6369H8.88546C10.3214 13.6369 11.4855 12.4729 11.4855 11.0369V5.49111C11.4855 4.05517 10.3214 2.89111 8.88546 2.89111H5.39785Z"
      />
      <defs>
        <linearGradient
          id={BOARDS_ICON_GRADIENT}
          x1="12"
          y1="24.1581"
          x2="5.00657"
          y2="1.56163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ABE0A" />
          <stop offset="1" stopColor="#D2EC34" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BoardsIconSvg;

const SPath = styled.path`
  fill: #a5aac5;

  .active & {
    fill: url(#${BOARDS_ICON_GRADIENT});
  }
`;
