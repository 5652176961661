import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import cn from 'classnames';
import { FC, ReactNode, useCallback, useRef, useState } from 'react';
import arrowIcon from 'students/views/shared/assets/icons/angle_arrow_right.svg';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

interface ICarousel {
  align?: 'prev' | 'center' | 'next';
  slidesVisible?: number;
  bounce?: string;
  withControls?: boolean;
  adaptive?: boolean;
  panels: ReactNode[];
  className?: string;
}

const Carousel: FC<ICarousel> = ({
  align = 'prev',
  slidesVisible = 4,
  bounce = '20%',
  withControls,
  adaptive = false,
  panels,
  className
}) => {
  const flickingRef = useRef<Flicking | null>();
  const [currentPanelIndex, setCurrentPanelIndex] = useState<number>(0);

  const handleOnNext = useCallback(() => {
    if (flickingRef?.current && !flickingRef.current?.control.animating) {
      flickingRef.current?.next();
    }
  }, []);

  const handleOnPrev = useCallback(() => {
    if (flickingRef?.current && !flickingRef.current?.control.animating) {
      flickingRef.current?.prev();
    }
  }, []);

  const setFlickingRef = useCallback((instance: Flicking) => {
    flickingRef.current = instance;
  }, []);

  const recalculateCurrentPanelIndex = useCallback(() => {
    if (flickingRef?.current) {
      setCurrentPanelIndex(flickingRef.current?.index ?? 0);
    }
  }, []);

  return (
    <SWrapper className={cn(className, 'Carousel')}>
      {withControls && currentPanelIndex + slidesVisible < panels.length ? (
        <SNextButton
          className="Carousel_Button Carousel_Button--next"
          onClick={handleOnNext}
        />
      ) : null}

      {withControls && currentPanelIndex > 0 ? (
        <SPrevButton
          className="Carousel_Button Carousel_Button--prev"
          onClick={handleOnPrev}
        />
      ) : null}

      <Flicking
        adaptive={adaptive}
        align={align}
        autoResize
        bound
        bounce={bounce}
        inputType={['touch', 'mouse']}
        ref={setFlickingRef}
        panelsPerView={slidesVisible}
        preventClickOnDrag
        onChanged={recalculateCurrentPanelIndex}
      >
        {panels.map((panel: ReactNode, index: number) => (
          <SPanel key={index} className="Carousel_Panel">
            {panel}
          </SPanel>
        ))}
      </Flicking>
    </SWrapper>
  );
};

export default Carousel;

const SWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SPanel = styled.div`
  margin-inline-end: -end: -end: 8px;
`;

const SPrevButton = styled.button`
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url(${arrowIcon});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 6px 12px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  padding: 12px;
  position: absolute;
  top: 50%;
  left: -8px;
  transform: rotate(180deg) translate(0, 50%);
  z-index: 2;

  ${customMediaQuery('desktop')} {
    left: -12px;
  }
`;

const SNextButton = styled.button`
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url(${arrowIcon});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 6px 12px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  padding: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  z-index: 2;

  ${customMediaQuery('desktop')} {
    right: -4px;
  }
`;
