import { ReactNode } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const SRootStyle = ({ children }: { children: ReactNode }): JSX.Element => (
  <>
    <GlobalStyles />

    <SRootStyles>{children}</SRootStyles>
  </>
);

const GlobalStyles = createGlobalStyle`
    // Common variables

    :root {
      --color-white: #ffffff;
      --color-light-white: #fbfcff;
      --color-red: #ff5858;
      --color-blue: #61DEFF;
      --color-black: #2d2d3a;
      --color-yellow: #ffc700;
      --color-grey: #A5AAC5;
      --color-light-grey: #f0f0f3;
      --color-green: #39B54A;
      --color-dark-red: rgb(230, 0, 0);
      --color-gray-100: rgb(249, 250, 253);
      --color-gray-200: rgb(237, 242, 249);
      --color-gray-300: rgb(216, 226, 239);
      --color-gray-400: rgb(182, 193, 210);
      --color-gray-500: rgb(157, 169, 187);
      --color-gray-600: rgb(116, 129, 148);
      --color-gray-700: rgb(94, 110, 130);
      --color-gray-800: rgb(77, 89, 105);
      --color-gray-900: rgb(52, 64, 80);
      --color-gray-1000: rgb(35, 46, 60);
      --color-gray-1100: rgb(11, 23, 39);
      --color-blue-100: rgb(178, 234, 249);
      --color-blue-200: rgb(153, 227, 247);
      --color-blue-300: rgb(128, 221, 246);
      --color-blue-400: rgb(102, 214, 243);
      --color-blue-500: rgb(77, 206, 242);
      --color-blue-600: rgb(51, 199, 240);
      --color-blue-700: rgb(0, 165, 215);
      --color-blue-800: rgb(0, 148, 197);
      --color-blue-900: rgb(0, 130, 178);
      --color-blue-1000: rgb(0, 117, 159);
      --color-blue-1200: rgb(0, 104, 142);
      --color-blue-1300: rgb(0, 89, 124);
      --color-blue-1400: rgb(0, 70, 111);
      --color-blue-btn-bg-disabled: rgb(209, 210, 212);
      --color-blue-btn-color-disabled: rgb(88, 88, 90);
      --color-blue-btn-focus-bg: rgb(0, 148, 197);
      --color-blue-btn-focus-outline-color: rgb(0, 148, 197);

      --lingu-font-light-color: rgb(251, 252, 255);
      --lingu-font-dark-color: rgb(11, 23, 39);
      --lingu-lesson-color: rgb(251, 252, 255);
      --lingu-lesson-button-color: rgb(0, 70, 111);
      --lingu-font-family: 'Inter', 'Product Sans', 'Helvetica', 'Arial', sans-serif;
      --lingu-heading-font-family: 'Inter', 'Product Sans', 'Helvetica', 'Arial', sans-serif;
      --lingu-text-font-family: 'Inter', 'Product Sans', 'Helvetica', 'Arial', sans-serif;

      --lingu-primary-submit-btn-bg-default: #61DEFF;
      --lingu-primary-submit-btn-bg-hover: rgb(0, 165, 215);
      --lingu-primary-submit-btn-bg-focus: rgb(0, 165, 215);
      --lingu-primary-submit-btn-bg-pressed: rgb(0, 104, 142);
      --lingu-primary-submit-btn-bg-disabled:rgb(230, 230, 240);

      --lingu-secondary-btn-bg-default: rgb(230, 230, 240);
      --lingu-secondary-btn-bg-hover: rgb(211, 211, 225);
      --lingu-secondary-btn-bg-focus: rgb(211, 211, 225);
      --lingu-secondary-btn-bg-pressed: rgb(157, 169, 187);
      --lingu-secondary-btn-bg-disabled:rgb(230, 230, 240);

      --lingu-light-btn-bg-default: rgb(255, 255, 255);
      --lingu-light-btn-bg-hover: rgb(211, 211, 225);
      --lingu-light-btn-bg-focus: rgb(211, 211, 225);
      --lingu-light-btn-bg-pressed: rgb(237, 242, 249);
      --lingu-light-btn-bg-disabled:rgb(230, 230, 240);

      --lingu-transparent-btn-bg-default: rgba(45,45, 58, 0.19607843137254902);
      --lingu-transparent-btn-bg-hover: rgba(45,45, 58, 0.5333333333333333);
      --lingu-transparent-btn-bg-focus: rgba(45,45, 58, 0.5333333333333333);
      --lingu-transparent-btn-bg-pressed: rgba(45,45, 58, 0.8);
      --lingu-transparent-btn-bg-disabled: rgba(4, 45, 58, 0.19607843137254902);

      --lingu-yellow-btn-bg-default: rgb(215, 223, 33);
      --lingu-yellow-btn-bg-hover: rgb(190, 214, 47);
      --lingu-yellow-btn-bg-focus: rgb(215, 223, 33);
      --lingu-yellow-btn-bg-active: rgb(190, 214, 47);
      --lingu-yellow-btn-bg-pressed: rgb(190, 214, 47);
      --lingu-yellow-btn-bg-disabled: rgb(215, 223, 33);

      --button-primary-text: #0B1420;
      --button-primary-bg: #61DEFF;
      // End Common variables
    }

    // Global styles
  
    .tooltip {
      &.show {
        opacity: 1;
      }

      .arrow {
        display: none;
      }

      .tooltip-inner {
        background: transparent;
        max-width: unset;
        text-align: start;;
        padding: 0;
      }
    }

    :root[dir='rtl'] .dir-auto {
      transform: scaleX(-1);
    }

    .onboarding-otp-check {
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  `;

const SRootStyles = styled.div`
  font-family: 'Inter', 'Product Sans', 'Helvetica', 'Arial', sans-serif;

  .container {
    max-width: 1210px;
  }

  .badge-warning {
    color: white;
  }

  .text-center {
    text-align: center;
  }

  .card-title {
    font-weight: 600;
    font-size: 1.8rem;
    margin-block-end: 0.75rem;
    line-height: 1.2;
    color: #344050;
  }

  p.inline-text {
    line-height: 40px;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .bg-blue {
    background-color: var(--color-blue);
  }

  .bg-white {
    background-color: white;
  }

  checkbox-box {
    border: 1px solid #344050;
  }

  .btn-submit {
    text-transform: uppercase;
    margin-block-start: 20px;
    background-color: var(--color-blue);
    border-radius: 5px;
    min-height: 60px;
    color: white;
    font-size: 1.1rem;
    font-weight: 800;
    min-width: 200px;
    &:hover {
      color: white;
      background-color: var(--color-blue);
    }
  }

  .white {
    color: white;
  }

  hr {
    margin: 12px 0;
  }

  h1,
  h2 {
    &.title {
      font-size: 2rem;
      color: #233048;
      letter-spacing: 0.25px;
      font-weight: bold;
    }
  }

  .introduction {
    letter-spacing: 0.3px;
    color: #222222;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.9rem;
  }

  .black {
    color: #4c5972;
  }
  hr {
    margin: 30px 0;
  }

  .task-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.06);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter', 'Product Sans', 'Helvetica', 'Arial', sans-serif;
    color: #344050;
    text-align: start;
    padding: 1rem;
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #344050;
      margin-block-end: 29px;
      font-family: 'Inter', 'Product Sans', 'Helvetica', 'Arial', sans-serif;
    }
    label {
      color: #344050;
      font-weight: 800;
    }
    @media (min-width: 576px) {
      padding: 1.8rem;
    }
  }

  .opacity-0 {
    opacity: 0;
  }

  & * {
    outline-color: var(--color-blue-btn-focus-outline-color) !important;
  }
`;

export default SRootStyle;
