import {
  useRef,
  useState,
  useEffect,
  useCallback,
  KeyboardEvent,
  AllHTMLAttributes
} from 'react';

interface IKeyboardListNavigationProps<T> {
  items: T[];
  onSelect: (item: T) => void;
}

interface IKeyboardListNavigationResult {
  activeItemIndex: number;
  listRef: React.RefObject<HTMLUListElement>;
  listHandler: (event: KeyboardEvent) => void;
}

interface IListHandlerConfig {
  listElem: HTMLUListElement;
  activeItemElem: HTMLLIElement;
  selectedItemElem: Element | null;
  nextItemElem: Element;
  activeItemIdx: number;
  totalItems: number;
  isKeyDownPressed: boolean;
}

interface IHandleTabOnNextItemParams {
  activeItemElem: HTMLLIElement;
  selectedItemElem: Element | null;
  nextItemElem: Element;
}

interface IKeyAction {
  ArrowDown: () => void;
  ArrowUp: () => void;
  ' ': () => void;
  Enter: () => void;
  Tab?: () => void;
}

const recalcActiveItemIndex = (config: IListHandlerConfig): number => {
  const { activeItemIdx, totalItems, isKeyDownPressed } = config;

  if (isKeyDownPressed) {
    return activeItemIdx >= totalItems - 1 ? 0 : activeItemIdx + 1;
  } else {
    return activeItemIdx <= 0 ? totalItems - 1 : activeItemIdx - 1;
  }
};

const getNextItemElem = (config: IListHandlerConfig) => {
  const { listElem, activeItemElem, activeItemIdx, totalItems } = config;

  let nextItem = null;

  if (config.isKeyDownPressed) {
    nextItem = activeItemElem.nextElementSibling;

    if (activeItemIdx === 0) {
      nextItem = listElem.firstElementChild;
    }
  } else {
    nextItem = activeItemElem.previousElementSibling;

    if (activeItemIdx === totalItems - 1) {
      nextItem = listElem.lastElementChild;
    }
  }

  return nextItem || activeItemElem;
};

const handleTabOnNextItem = (config: IHandleTabOnNextItemParams) => {
  const { nextItemElem, activeItemElem, selectedItemElem } = config;

  activeItemElem.removeAttribute('tabindex');
  nextItemElem.setAttribute('tabindex', '0');

  if (selectedItemElem === nextItemElem) return;

  nextItemElem.setAttribute('aria-selected', 'false');
};

const scrollListToNextItem = (nextItemElem: Element) => {
  nextItemElem.scrollIntoView({
    behavior: 'auto',
    block: 'nearest'
  });
};

const useAccessibleListNavigation = <T>({
  items,
  onSelect
}: IKeyboardListNavigationProps<T>): IKeyboardListNavigationResult => {
  const [selectedItemIdx, setSelectedItemIndex] = useState(-1);
  const [activeItemIdx, setActiveItemIndex] = useState(-1);
  const activeItemRef = useRef<null | HTMLLIElement>(null);
  const listRef = useRef<null | HTMLUListElement>(null);

  const listHandler = useCallback(
    (e: KeyboardEvent) => {
      if (!activeItemRef.current || !listRef.current) return;

      const config: IListHandlerConfig = {
        listElem: listRef.current,
        activeItemElem: activeItemRef.current,
        selectedItemElem: listRef.current.children[selectedItemIdx] || null,
        nextItemElem: activeItemRef.current,
        activeItemIdx: activeItemIdx ?? -1,
        totalItems: items.length,
        isKeyDownPressed: true
      };

      const handleArrowKey = (isKeyDownPressed: boolean) => {
        config.isKeyDownPressed = isKeyDownPressed;
        config.activeItemIdx = recalcActiveItemIndex(config);
        config.nextItemElem = getNextItemElem(config);

        handleTabOnNextItem(config);
        scrollListToNextItem(config.nextItemElem);
        setActiveItemIndex(config.activeItemIdx);
      };

      const handleSelectKeys = () => {
        onSelect(items[config.activeItemIdx]);
        setSelectedItemIndex(activeItemIdx);

        config.selectedItemElem?.setAttribute('aria-selected', 'false');
        config.activeItemElem.setAttribute('aria-selected', 'true');
      };

      const keyActions: IKeyAction = {
        ArrowDown: () => handleArrowKey(true),
        ArrowUp: () => handleArrowKey(false),
        ' ': handleSelectKeys,
        Enter: handleSelectKeys
      };

      Object.keys(keyActions).forEach((k) => e.key === k && e.preventDefault());
      keyActions.Tab = () => config.activeItemElem.removeAttribute('tabindex');

      const action = keyActions[e.key as keyof IKeyAction];

      if (action) action();
    },
    [activeItemIdx, selectedItemIdx, items, onSelect]
  );

  useEffect(() => {
    const requiredAttributes: AllHTMLAttributes<HTMLUListElement> = {
      tabIndex: 0,
      role: 'listbox',
      'aria-setsize': items.length
    };

    Object.entries(requiredAttributes).forEach(([key, value]) => {
      listRef.current?.setAttribute(key, value);
    });
  }, [items.length]);

  useEffect(() => {
    if (!listRef.current) return;

    const currentActiveItemIdx = activeItemIdx < 0 ? 0 : activeItemIdx;
    const activeItem = listRef.current.children[currentActiveItemIdx];

    activeItem.setAttribute('role', 'option');
    activeItemRef.current = (activeItem as HTMLLIElement) || null;
  }, [activeItemIdx]);

  useEffect(() => activeItemRef.current?.focus(), [activeItemIdx]);

  return { listRef, listHandler, activeItemIndex: activeItemIdx };
};

export default useAccessibleListNavigation;
