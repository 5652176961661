import React from 'react';
import styled from 'styled-components';

const HOME_ICON_GRADIENT = 'navigationHomeGradient';

const HomeIconSvg: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <SPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6045 2.8475C11.3761 2.08241 12.6179 2.07721 13.393 2.83877C15.3249 4.73708 17.2334 6.66024 19.1415 8.58312C20.194 9.64369 21.2464 10.7042 22.3025 11.7603C22.8246 12.2824 22.8246 13.1288 22.3025 13.6508C21.7805 14.1728 20.9341 14.1728 20.4121 13.6508L20.0206 13.2593V21.3995C20.0206 22.5041 19.1251 23.3995 18.0206 23.3995H5.97964C4.87507 23.3995 3.97964 22.5041 3.97964 21.3995V13.2593L3.58812 13.6508C3.06609 14.1728 2.21971 14.1728 1.69768 13.6508C1.17564 13.1288 1.17564 12.2824 1.69768 11.7603C2.75571 10.7023 3.81184 9.64225 4.86798 8.58216C6.7763 6.66672 8.68469 4.75122 10.6045 2.8475ZM8.02624 14.2766C8.02624 12.1313 9.81801 10.3658 12.0233 10.3658C14.2285 10.3658 15.9743 12.1313 15.9743 14.4107C15.9743 16.4666 14.5501 18.2097 12.0233 20.4221V17.9416H11.8625C9.49641 17.9416 8.02624 16.489 8.02624 14.2766Z"
      />
      <defs>
        <linearGradient
          id={HOME_ICON_GRADIENT}
          x1="18.6553"
          y1="27.2447"
          x2="8.08997"
          y2="2.27977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0C98C4" />
          <stop offset="1" stopColor="#03C9F7" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HomeIconSvg;

const SPath = styled.path`
  fill: #a5aac5;

  .active & {
    fill: url(#${HOME_ICON_GRADIENT});
  }
`;
