export type tDevicesTypes = 'mobile' | 'tablet' | 'desktop';

const DEFAULT_WIDTH: Record<string, number> = {
  mobile: 320,
  tablet: 644,
  desktop: 1024
};

export const customMediaQuery =
  (device: tDevicesTypes) =>
  ({ theme }: { theme: Record<string, any> }): string => {
    switch (device) {
      case 'mobile':
        return `@media(max-width: ${
          theme.mediaQueryBreakpoints?.tablet || DEFAULT_WIDTH.tablet
        }px)`;

      case 'tablet':
        return `@media(min-width: ${
          theme.mediaQueryBreakpoints?.table || DEFAULT_WIDTH.tablet
        }px)`;

      case 'desktop':
        return `@media(min-width: ${
          theme.mediaQueryBreakpoints?.desktop || DEFAULT_WIDTH.desktop
        }px)`;
      default:
        return '';
    }
  };
