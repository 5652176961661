import { ChangeEvent, useCallback, useState } from 'react';
import {
  ILanguageInstance,
  IStudentTargetLanguage
} from '@adeptlms/lingu-students-react-shared';
import { useTypedSelector } from 'students/stores/_utils';
import { userSelectors } from 'students/stores/user';

interface IReturnedValue {
  searchQuery: string;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;
  filteredLanguages: ILanguageInstance[];
  currentTargetLanguage?: IStudentTargetLanguage | undefined;
}

function useLanguagesList(languages: ILanguageInstance[]): IReturnedValue {
  const currentTargetLanguage = useTypedSelector(
    userSelectors.selectProfileCurrentTargetLanguage
  );
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value.toLowerCase());
    },
    [setSearchQuery]
  );

  const clearSearch = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const filteredLanguages = searchQuery
    ? languages.filter((language: ILanguageInstance) =>
        language.systemName.toLowerCase().startsWith(searchQuery.trim())
      )
    : languages;

  return {
    searchQuery,
    handleInputChange,
    clearSearch,
    filteredLanguages,
    currentTargetLanguage
  };
}

export default useLanguagesList;
