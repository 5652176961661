import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { modalActions } from 'students/stores/modal';

interface IReturnedObject {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
  closeModal: (modalKey: string) => ReturnType<typeof modalActions.close>;
  isFirstStep: boolean;
  isLastStep: boolean;
}

export const useLangSelect = ({
  quantitySteps
}: {
  quantitySteps: number;
}): IReturnedObject => {
  const dispatch = useDispatch();
  const closeModal = (modalKey: string) => dispatch(modalActions.close(modalKey));
  const [step, setStep] = useState(0);
  const isFirstStep = step === 0;
  const isLastStep = step === quantitySteps - 1;
  const nextStep = useCallback(() => {
    if (isLastStep) {
      return;
    }
    setStep(step + 1);
  }, [step, isLastStep]);

  const prevStep = useCallback(() => {
    if (!step) {
      return;
    }
    setStep(step - 1);
  }, [step, setStep]);

  return {
    step,
    nextStep,
    prevStep,
    closeModal,
    isFirstStep,
    isLastStep
  };
};
