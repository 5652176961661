import { ComponentType, lazy, LazyExoticComponent } from 'react';

const PAGE_HAS_BEEN_REFRESHED_TIME = 'page-has-been-refreshed-time';
const REFRESH_TIMEOUT = 15000;

function lazyWithRetry<T extends ComponentType<any>>(
  componentImport: () => any
): LazyExoticComponent<T> {
  return lazy(async function () {
    const pageHasBeenRefreshedTimeString = window.sessionStorage.getItem(
      PAGE_HAS_BEEN_REFRESHED_TIME
    );

    try {
      const component = await componentImport();

      return component;
    } catch (error) {
      const errorTime = Date.now();

      if (
        !pageHasBeenRefreshedTimeString ||
        (pageHasBeenRefreshedTimeString &&
          errorTime - parseInt(pageHasBeenRefreshedTimeString, 10) > REFRESH_TIMEOUT)
      ) {
        window.sessionStorage.setItem(PAGE_HAS_BEEN_REFRESHED_TIME, String(errorTime));

        return window.location.reload();
      }

      throw error;
    }
  });
}

export default lazyWithRetry;
