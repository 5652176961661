import { useCallback, useEffect, useRef } from 'react';
import { TaskAudioManager } from '../classes';
import {
  IAudioManagerReturnedObject,
  ITaskAudioManagerItem,
  ITaskAudioRepositoryCollectionItem,
  tTaskAudioRepositoryCollection
} from '../models';

const useAudioManager = (): IAudioManagerReturnedObject => {
  const managerRef = useRef<TaskAudioManager>(new TaskAudioManager([]));

  const add = useCallback((item: ITaskAudioManagerItem): void => {
    managerRef.current.add(item);
  }, []);

  const addUnique = useCallback((item: ITaskAudioManagerItem): void => {
    managerRef.current.addUnique(item);
  }, []);

  const reset = useCallback((): void => {
    managerRef.current.reset();
  }, []);

  const unregister = useCallback((id: string | number): void => {
    managerRef.current.unregister(id);
  }, []);

  const unregisterUncached = useCallback((id: string | number): void => {
    managerRef.current.unregisterUncached(id);
  }, []);

  const unsubscribe = useCallback((): void => {
    managerRef.current.unsubscribe();
  }, []);

  const unload = useCallback((): void => {
    managerRef.current.unload();
  }, []);

  const stop = useCallback((): void => {
    managerRef.current.stop();
  }, []);

  const visibilityPause = useCallback((): void => {
    managerRef.current.visibilityPause();
  }, []);

  const visibilityPlay = useCallback((): void => {
    managerRef.current.visibilityPlay();
  }, []);

  const playById = useCallback((id: string | number): void => {
    return managerRef.current.playById(id);
  }, []);

  const getById = useCallback(
    (id: string | number): ITaskAudioRepositoryCollectionItem | null => {
      return managerRef.current.getById(id);
    },
    []
  );

  const getByTag = useCallback((tag: string): tTaskAudioRepositoryCollection => {
    return managerRef.current.getByTag(tag);
  }, []);

  const getAll = useCallback((): tTaskAudioRepositoryCollection => {
    return managerRef.current.getAll();
  }, []);

  const addTagToMute = useCallback((tag: string): void => {
    managerRef.current.addTagToMute(tag);
  }, []);

  const removeTagFromMute = useCallback((tag: string): void => {
    managerRef.current.removeTagFromMute(tag);
  }, []);

  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, [unsubscribe]);

  return {
    add,
    addUnique,
    reset,
    unregister,
    unregisterUncached,
    unsubscribe,
    unload,
    stop,
    visibilityPause,
    visibilityPlay,
    playById,
    getById,
    getByTag,
    getAll,
    addTagToMute,
    removeTagFromMute,
    manager: managerRef.current
  };
};

export default useAudioManager;
