import { tDevicesTypes } from 'students/views/shared/styled';

export const contentMaxWidth: Record<tDevicesTypes, number> = {
  mobile: 320,
  tablet: 554,
  desktop: 844
};

export const navigationBreakPoint = 890;
export const navigationDesktopBreakPoint = 1200;

export const mediaQueryBreakpoints: Record<tDevicesTypes, number> = {
  mobile: 320,
  tablet: 600,
  desktop: 908
};
