import React, { FC } from 'react';
import cn from 'classnames';
import { Translate, t } from 'i18n';
import localeFlags from 'i18n/config/localeFlags';

import { useAccessibleListNavigation } from 'students/views/shared/hooks';
import { ILanguageInstance } from '@adeptlms/lingu-students-react-shared';
import {
  SLanguagesListSearch,
  SLanguagesListSearchInput,
  SNoResultsMessage,
  SCloseButton,
  SListItem,
  SFlagImg,
  SPicked,
  SList,
  SSecondaryText
} from './styles';
import { ILanguagesList } from './model';
import useLanguagesList from './useLanguagesList';
import { CloseLightIcon } from '../../../../icons';

export const LanguagesList: FC<ILanguagesList> = ({
  languages,
  pickedLanIds,
  pickLang,
  hasFlag,
  withSearch,
  withAdditionalInfo
}) => {
  const {
    searchQuery,
    handleInputChange,
    clearSearch,
    filteredLanguages,
    currentTargetLanguage
  } = useLanguagesList(languages);

  const { listRef, listHandler, activeItemIndex } =
    useAccessibleListNavigation<ILanguageInstance>({
      items: filteredLanguages,
      onSelect: (lang) => pickLang(lang.id)
    });

  return (
    <div data-testid="students/components/LanguagesList">
      {withSearch ? (
        <SLanguagesListSearch>
          <SLanguagesListSearchInput
            data-testid="students/components/LanguagesList/SearchInput"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder={t('frontend.search_placeholder')}
          />

          {searchQuery ? (
            <SCloseButton
              onClick={clearSearch}
              data-testid="students/components/LanguagesList/CloseButton"
            >
              <CloseLightIcon height="12px" width="12px" />
            </SCloseButton>
          ) : null}
        </SLanguagesListSearch>
      ) : null}

      <SList ref={listRef} onKeyDown={listHandler}>
        {filteredLanguages.length ? (
          filteredLanguages.map((lan, idx) => {
            const flag = localeFlags.get(lan.code)?.flag;
            const onClick = () => pickLang(lan.id);
            const isPicked = !!pickedLanIds.find(
              (id: string) => id === `${lan.id}` || id === lan.code
            );
            return (
              <SListItem
                className={cn({ isPicked, isActive: activeItemIndex === idx })}
                data-testid="LanguagesList_item"
                onClick={onClick}
                key={lan.id}
              >
                {hasFlag && <SFlagImg src={flag} />}

                {lan.systemName.trim()}

                {withAdditionalInfo && lan.id === currentTargetLanguage?.languageId ? (
                  <SSecondaryText>
                    <Translate str="frontend.member.settings.pages.training_settings.set_as_i_learn" />
                  </SSecondaryText>
                ) : null}

                {isPicked && <SPicked data-testid="LanguagesList_lanActive" />}
              </SListItem>
            );
          })
        ) : (
          <SNoResultsMessage data-testid="students/components/LanguagesList/NotFoundMessage">
            <Translate str="frontend.course.no_results" />
          </SNoResultsMessage>
        )}
      </SList>
    </div>
  );
};
