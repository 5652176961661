import { useContext, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { modalActions } from 'students/stores/modal';
import { useOutsideClick } from 'students/views/shared/hooks';
import useSupportEqualsTargetLanguage from 'students/views/shared/bundles/sameTargetAndSupportLangs/SupportLangNotification/hooks/useSupportEqualsTargetLanguage';
import { I18nContext, locales } from 'i18n';
import { useLangDropdown } from '../../useLangDropdown';

interface IReturnedObject {
  currentSupportLanguageNativeName?: string;
  menuRef: React.MutableRefObject<null>;
  onShow: () => void;
  openMenu: () => ReturnType<typeof modalActions.open>;
  setSupportLanguages: (languageId: number) => void;
  show: boolean;
  studentSupportLanguagesInfo:
    | {
        flag?: string;
        isActive: boolean;
        languageId: number;
        systemName?: string;
      }[]
    | undefined;
  languagesAreEqual: boolean;
}

export function useSupportDropdown(): IReturnedObject {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const languagesAreEqual = useSupportEqualsTargetLanguage();
  const {
    show,
    buttonRef,
    onHide,
    onShow,
    activeSupportLanguageId,
    getSupportLanguageInfo,
    studentSupportLanguages,
    setSupportLanguages
  } = useLangDropdown();
  useOutsideClick(menuRef, onHide, [buttonRef]);

  const openMenu = () => dispatch(modalActions.open('supportLangSelect'));
  const { locale } = useContext(I18nContext);
  const currentSupportLanguageNativeName = locales.find(
    ({ lKey }) => lKey === locale
  )?.nativeName;

  const studentSupportLanguagesInfo = useMemo(
    () =>
      studentSupportLanguages?.map(({ languageId }) => {
        const { systemName, flag } = getSupportLanguageInfo(languageId);
        const isActive = activeSupportLanguageId === languageId;
        return {
          flag,
          isActive,
          languageId,
          systemName
        };
      }),
    [activeSupportLanguageId, getSupportLanguageInfo, studentSupportLanguages]
  );

  return {
    currentSupportLanguageNativeName,
    menuRef,
    onShow,
    openMenu,
    setSupportLanguages,
    show,
    studentSupportLanguagesInfo,
    languagesAreEqual
  };
}
