import { IProfile } from '@adeptlms/lingu-students-react-shared';
import { AnimatePresence, motion } from 'framer-motion';
import { Translate } from 'i18n';
import React, { useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SignInButton } from 'students/views/screens/Auth';
import ArrowRightIcon from 'students/views/shared/assets/icons/arrow_right_icon.svg';
import AvatarImage from 'students/views/shared/components/AvatarImage';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';
import { styleInnerButton } from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { useOutsideClick } from 'students/views/shared/hooks';
import styled from 'styled-components';

import { navigationDesktopBreakPoint } from 'students/views/pages/Member/components/theme/breakpoints';
import LinkElem from 'students/views/shared/components/LinkElem';
import { TEACHER_APP_URL } from '../GoToTeacherArea';

interface IProps {
  userProfile: IProfile | null;
  handleLogOut: () => void;
}

const ProfileMenu: React.FC<IProps> = ({ userProfile, handleLogOut }) => {
  const [show, setShow] = useState(false);
  const menuRef = useRef(null);
  const logoRef = useRef(null);
  const name = `${userProfile?.fname} ${userProfile?.lname}`;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  const toggleShow = useCallback(() => {
    setShow((prevShow) => !prevShow);
  }, [setShow]);

  const closePopover = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const onLogout = useCallback(() => {
    setIsLoading(true);
    handleLogOut();
  }, [handleLogOut]);

  useOutsideClick(menuRef, closePopover, [logoRef]);

  return (
    <SWrapper>
      {userProfile ? (
        <SProfileImageButton ref={logoRef} onClick={toggleShow}>
          <SAvatar
            src={userProfile.avatarURL}
            alt={`User ${name}`}
            className="ln-flex-shrink-0"
          />
          <SProfileName className="ln-min-w-0 ln-truncate" title={name}>
            {name}
          </SProfileName>

          <SDottedIcon>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 5.5v.01m0 6.99v.01m0 6.99v.01M12 6.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                opacity=".5"
              />
            </svg>
          </SDottedIcon>
        </SProfileImageButton>
      ) : (
        <SignInButton />
      )}
      <AnimatePresence>
        {show && (
          <SPopover className="profile-menu">
            <SProfileMenu
              transition={{ duration: 0.2 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              ref={menuRef}
            >
              {userProfile?.isTeacher && (
                <>
                  <SLink to={TEACHER_APP_URL} className="ln-flex ln-items-center">
                    <Translate str="frontend.profile.navigation.go_to_teacher_area" />
                    <UrlIcon
                      className="ln-ms-1 ln-relative ln-top-0.5"
                      url={ArrowRightIcon}
                      height="0.9rem"
                      width="0.9rem"
                      color="#0B1420"
                    />
                  </SLink>

                  <Devider />
                </>
              )}

              <SLink to="/activity">
                {/* <Translate str="frontend.profile.activity.my_activity" /> */}
                My activity
              </SLink>

              <SLink to={`/settings/account?from=${location.pathname}`}>
                <Translate str="frontend.profile.navigation.my_profile" />
              </SLink>

              <SLink to={`/settings?from=${location.pathname}`} onClick={closePopover}>
                <Translate str="frontend.profile.navigation.settings" />
              </SLink>

              <Devider />

              <SLogOutButton onClick={onLogout} disabled={isLoading}>
                <Translate str="frontend.profile.navigation.log_out" />
              </SLogOutButton>
            </SProfileMenu>
          </SPopover>
        )}
      </AnimatePresence>
    </SWrapper>
  );
};

export default ProfileMenu;

const Devider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #0b1420;
  opacity: 0.1;
  margin: 8px 0;
`;

const SDottedIcon = styled.div`
  display: none;
  margin-inline-start: auto;
  padding-block: 0;
  padding-inline: 2px 10px;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    display: block;
  }
`;

const SWrapper = styled.div`
  position: relative;
`;

const SPopover = styled.div`
  display: block;
  top: 44px;
  inset-inline-end: 0;
  min-width: 128px;
  position: absolute;
  z-index: 10;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    &.profile-menu {
      bottom: 16px;
      top: auto;
    }
  }
`;

const SProfileMenu = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  background-color: var(--color-white);
  border: none;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;

  a {
    color: var(--color-black);
    font-size: 14px;
    line-height: 17px;
    padding: 2px 0;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const SLink = styled(LinkElem)`
  white-space: nowrap;
  margin: 6px 0;
  letter-spacing: -0.02em;
`;

const SLogOutButton = styled(ButtonGeneral)`
  background-color: transparent;
  border: none;
  text-align: start;

  ${styleInnerButton()} {
    border-radius: 2px;
    letter-spacing: -0.02em;
    color: var(--color-black);
    opacity: 0.6;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 0;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

const SProfileImageButton = styled(ButtonGeneral)`
  position: relative;
  display: block;
  background: transparent;
  width: 100%;
  color: #ffffff;
  padding: 4px;
  border: none;
  transition: background 0.2s ease;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  & > .button-inner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: initial;
    font-weight: 600;
  }
`;

const SAvatar = styled(AvatarImage)`
  vertical-align: middle;
  height: 2rem;
  width: 2rem;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    height: 2.5rem;
    width: 2.5rem;
  }
  img {
    object-fit: contain;
  }
`;

const SProfileName = styled.p`
  display: none;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    margin-inline-start: 12px;
  }
`;
