import { Translate } from 'i18n';
import React from 'react';
import styled from 'styled-components';

import { IconLink, linkStyles } from '../styles';

import { MessagesIcon } from '../config';

import { navigationDesktopBreakPoint } from '../../theme/breakpoints';

function ChatIcon({ className }: { className?: string }) {
  return (
    <SWrapper className={className}>
      <lingu-button>
        <SIconLink $src={MessagesIcon} />
        <Translate scope="frontend.mobile_tabs" str="messages" />
      </lingu-button>
      <lingu-badge />
    </SWrapper>
  );
}

export default ChatIcon;

declare global {
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface IntrinsicElements {
      'lingu-badge': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { styles?: string };

      'lingu-button': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { styles?: string };
    }
  }
}

const SIconLink = styled(IconLink)`
  display: none;
  color: #a3a6ab;

  @media (min-width: ${navigationDesktopBreakPoint}px) {
    display: inline-block;
  }
`;

const SWrapper = styled.div`
  position: relative;
  padding: 0;
  width: 100%;

  & lingu-button::part(button) {
    ${linkStyles}
    width: 100%;
    padding-inline-end: 40px;
  }

  & lingu-badge::part(badge) {
    position: absolute;
    top: calc(50% - 13px);
    inset-inline-end: 10px;
    border-radius: 8px;
    background-color: #e1ff6a;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 15px;
    font-weight: 700;
    color: #050d2c;

    pointer-events: none;
  }
`;
