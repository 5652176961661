import { SMemberButton } from 'students/views/pages/Member/components/common';
import styled from 'styled-components';

import ButtonGeneral, { styleInnerButton } from '../../ButtonGeneral/ButtonGeneral';
import { BackArrowIcon, CloseIcon } from '../../icons';

export const SWrapper = styled.div`
  padding: 21px 16px;
  height: max-content;
  max-height: 462px;
`;

export const STitle = styled.div`
  color: var(--heading-text-color);
  font-style: normal;
  font-weight: bold;
  font-size: 1.375rem;
  text-align: start;

  @media (max-width: 500px) {
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin-block-end: 15px;
  }
`;

export const SButtons = styled.div`
  display: flex;
  padding-block: 0 16px;
  padding-inline: 16px;

  @media (max-width: 500px) {
    padding-block-start: 20px;
    border-top: 1px solid rgba(93, 98, 124, 0.3);
  }
`;

export const SCancel = styled(SMemberButton)`
  min-width: 108px;
  height: 48px;
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2.25rem;

  ${styleInnerButton()} {
    padding: 12px;
  }

  @media (max-width: 500px) {
    min-width: 100px;
    width: 100%;
  }
`;

export const SNext = styled(SMemberButton)`
  min-width: 220px;
  height: 48px;
  border-radius: 10px;
  margin-inline-start: 12px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1rem;
  line-height: 2.25rem;
  width: 100%;

  ${styleInnerButton()} {
    padding: 12px;
  }

  @media (max-width: 375px) {
    min-width: 160px;
  }
`;

export const SContent = styled.div`
  margin-block-start: 4px;
`;

export const SBackArrowIcon = styled(BackArrowIcon)`
  min-height: 16px;
  min-width: 16px;
`;

export const SBackButton = styled(ButtonGeneral)`
  margin-inline-end: 16px;
  background: transparent;
`;

export const SCloseIcon = styled(CloseIcon)`
  min-height: 16px;
  min-width: 16px;
`;

export const SCloseButton = styled(ButtonGeneral)`
  position: absolute;
  top: 16px;
  inset-inline-end: 16px;
  cursor: pointer;
  width: 12px;
  height: 12px;
  padding: 10px;
  box-sizing: content-box;
  border-radius: 4px;
  background: transparent;

  &:hover {
    background: var(--common-secondary-member-button-background);
  }

  @media (max-width: 500px) {
    display: none;
  }
`;
