import cn from 'classnames';
import { Translate } from 'i18n';
import React from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

interface IProps {
  fullNameLocaleKey?: string;
  providerName?: string;
  logo: string;
  webpLogo: string;
  fallbackLogo: string;
  link?: string;
  className?: string;
  onClick?: (link?: string) => void;
}
const SocialButton: React.FC<IProps> = ({
  providerName,
  fullNameLocaleKey,
  logo,
  webpLogo,
  fallbackLogo,
  link,
  className,
  onClick
}) => {
  const handleClick = () => {
    onClick && onClick(link);
  };

  const text = fullNameLocaleKey ? (
    <Translate str={fullNameLocaleKey} />
  ) : (
    <Translate
      str="frontend.auth.continue_with_provider"
      params={{ provider: providerName }}
    />
  );
  return (
    <SSocialButton
      type="button"
      className={cn('ln-w-full', className)}
      onClick={handleClick}
    >
      <picture>
        <source srcSet={logo} type="image/svg+xml" />
        <source srcSet={webpLogo} type="image/webp" />
        <SLogo src={fallbackLogo} alt="" />
      </picture>
      <SButtonText>{text}</SButtonText>
    </SSocialButton>
  );
};

export default SocialButton;

const SLogo = styled.img`
  width: 1.75rem;
  height: 1.75rem;
  display: block;
  margin-inline-end: 1rem;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);

  ${customMediaQuery('desktop')} {
    width: 2rem;
    height: 2rem;
  }
`;

const SSocialButton = styled.button`
  border-color: #e6e6f0;
  background-color: var(--color-white);
  border-width: 2px;
  border-radius: 0.875rem;
  min-height: 3.5rem;
  color: var(--color-black);
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  padding: 0.875rem;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.2s ease-in-out;

  &:focus,
  &:active,
  &:hover {
    background-color: var(--color-white);
    color: var(--color-black);
    border-color: var(--color-black);
  }

  ${customMediaQuery('mobile')} {
    padding-inline-start: 2.5rem;
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const SButtonText = styled.div`
  text-align: center;
  flex-grow: 1;
`;
