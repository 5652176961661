import styled, { css } from 'styled-components';

import ButtonGeneral from '../components/ButtonGeneral';
import { styleInnerButton } from '../components/ButtonGeneral/ButtonGeneral';

export const SButton = styled(ButtonGeneral)`
  min-height: 3.75rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 2.25rem;
  border-radius: 10px;
  margin-block-start: 1rem;
  margin-block-end: 1rem;

  @media (min-width: ${({ theme }) => theme.linguBptMd}) {
    font-size: 1.375rem;
    min-height: 4rem;
  }
`;

export const SPrimaryButton = styled(SButton)`
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: 0;

  &:hover,
  ${styleInnerButton('focus')} {
    background-color: var(--lingu-primary-submit-btn-bg-hover);
  }

  &:active {
    background-color: var(--lingu-primary-submit-btn-bg-pressed);
  }

  &:disabled {
    background-color: var(--lingu-primary-submit-btn-bg-disabled);
    border: 0;
  }
`;

export const SSecondaryButton = styled(SButton)`
  background-color: var(--lingu-secondary-btn-bg-default);
  display: block;

  &,
  &:hover,
  ${styleInnerButton('focus')}, &:active,
  &:disabled {
    color: var(--lingu-font-dark-color) !important;
    border-color: var(--lingu-secondary-btn-bg-default);
    box-shadow: none !important;
    border: none;
  }

  &:hover,
  ${styleInnerButton('focus')} {
    background-color: var(--lingu-secondary-btn-bg-hover);
  }

  &:active,
  &:pressed {
    background-color: var(--lingu-secondary-btn-bg-pressed);
  }

  &:disabled {
    background-color: var(--lingu-secondary-btn-bg-disabled);
  }
`;

export const SLightButton = styled(SButton)`
  background-color: var(--lingu-light-btn-bg-default);
  border-color: var(--lingu-light-btn-bg-default);

  &:hover,
  ${styleInnerButton('focus')} {
    background-color: var(--lingu-light-btn-bg-hover);
    border-color: var(--lingu-light-btn-bg-hover);
  }

  &:active {
    background-color: var(--lingu-light-btn-bg-pressed);
    border-color: var(--lingu-light-btn-bg-pressed);
  }

  &:disabled {
    background-color: var(--lingu-light-btn-bg-disabled);
    border-color: var(--lingu-light-btn-bg-disabled);
    border: 0;
  }
`;

export const STransparentButton = styled(SButton)`
  background-color: var(--lingu-transparent-btn-bg-default);
  border: 2px solid transparent;
  border-color: transparent !important;

  &:hover {
    background-color: var(--lingu-transparent-btn-bg-hover);
    border-color: var(--lingu-transparent-btn-bg-hover);
  }

  ${styleInnerButton('focus')} {
    background-color: var(--lingu-transparent-btn-bg-focus);
    border-color: var(--color-blue-400);
    border-width: 2px;
    border-style: solid;
  }

  &:active {
    background-color: var(--lingu-transparent-btn-bg-pressed);
    border-color: var(--lingu-transparent-btn-bg-pressed);
  }

  &:disabled {
    background-color: var(--lingu-transparent-btn-bg-disabled);
    border-color: var(--lingu-transparent-btn-bg-disabled);
    border: 0;
    opacity: 0.2;
  }
`;

export const SYellowButton = styled(SButton)`
  color: #00466f;
  background-color: var(--lingu-yellow-btn-bg-default);
  border-color: var(--lingu-yellow-btn-bg-default);

  &:hover {
    color: #00466f;
    background-color: var(--lingu-yellow-btn-bg-hover);
    border-color: var(--lingu-yellow-btn-bg-hover);
  }

  ${styleInnerButton('focus')} {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
      #d7df21;
  }

  &:active {
    color: #00466f;
    background-color: var(--lingu-yellow-btn-bg-pressed);
    border-color: var(--lingu-yellow-btn-bg-pressed);
  }

  &:disabled {
    color: #00466f;
    background-color: var(--lingu-yellow-btn-bg-disabled);
    border-color: var(--lingu-yellow-btn-bg-disabled);
    border: 0;
    opacity: 0.2;
  }
`;

export const buttonStylesReset = css`
  background-color: transparent;
  border-color: transparent;
  border: 0;

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    background-color: transparent;
    border-color: transparent;
    border: 0;
  }
`;
export const SNoStyleButton = styled.button`
  ${buttonStylesReset}
`;

export const SNoStyleGeneralButton = styled(SButton)`
  background-color: transparent;
  border-color: transparent;
  border: 0;
  margin: 0;
  min-height: 1rem !important;

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    background-color: transparent;
    border-color: transparent;
    border: 0;
  }
`;
