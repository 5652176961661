import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { SNoStyleGeneralButton } from '../../styled';
import UrlIcon from '../UrlIcon';
import closeIcon from '../../assets/icons/close_icon.svg';

type tProps = {
  className?: string;
} & ComponentProps<typeof SNoStyleGeneralButton>;
const CloseButton: React.FC<tProps> = ({ className, ...props }) => {
  return (
    <SCloseButton className={cn(className)} {...props}>
      <UrlIcon url={closeIcon} height="16px" width="16px" color="#59618D" />
    </SCloseButton>
  );
};

export default CloseButton;

const SCloseButton = styled(SNoStyleGeneralButton)`
  padding: 4px;
`;
