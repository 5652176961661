import { tLevel } from '@adeptlms/lingu-students-react-shared';

export function getTypedLevel(level: string): tLevel {
  switch (level) {
    case 'a1':
      return 'a1';
    case 'a2':
      return 'a2';
    case 'b1':
      return 'b1';
    case 'b2':
      return 'b2';
    case 'c1':
      return 'c1';
    case 'c2':
      return 'c2';

    default:
      return 'undefined';
  }
}
