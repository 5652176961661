import GLogo from 'students/views/shared/assets/providers/google/google-logo.png';
import FBLogo from 'students/views/shared/assets/providers/facebook/facebook-logo.png';
import MSLogo from 'students/views/shared/assets/providers/microsoft/microsoft-logo.png';
import AppleLogo from 'students/views/shared/assets/providers/apple/apple-logo.png';
import FeideLogo from 'students/views/shared/assets/providers/feide/feide-logo.png';

import GLogoWebp from 'students/views/shared/assets/providers/google/google-logo.webp';
import FBLogoWebp from 'students/views/shared/assets/providers/facebook/facebook-logo.webp';
import MSLogoWebp from 'students/views/shared/assets/providers/microsoft/microsoft-logo.webp';
import AppleLogoWebp from 'students/views/shared/assets/providers/apple/apple-logo.webp';
import FeideLogoWebp from 'students/views/shared/assets/providers/feide/feide-logo.webp';

import GLogoSVG from 'students/views/shared/assets/providers/google/google-logo.svg';
import FBLogoSVG from 'students/views/shared/assets/providers/facebook/facebook-logo.svg';
import MSLogoSVG from 'students/views/shared/assets/providers/microsoft/microsoft-logo.svg';
import AppleLogoSVG from 'students/views/shared/assets/providers/apple/apple-logo.svg';
import FeideLogoSVG from 'students/views/shared/assets/providers/feide/feide-logo.svg';

const socials: {
  link: string;
  providerName: string;
  logo: string;
  webpLogo: string;
  fallbackLogo: string;
}[] = [
  {
    link: '/auth/google_student',
    providerName: 'Google',
    logo: GLogoSVG,
    webpLogo: GLogoWebp,
    fallbackLogo: GLogo
  },
  {
    link: '/auth/facebook_student',
    providerName: 'Facebook',
    logo: FBLogoSVG,
    webpLogo: FBLogoWebp,
    fallbackLogo: FBLogo
  },
  {
    link: '/auth/microsoft_student',
    providerName: 'Microsoft',
    logo: MSLogoSVG,
    webpLogo: MSLogoWebp,
    fallbackLogo: MSLogo
  },
  {
    link: '/auth/apple_student',
    providerName: 'Apple',
    logo: AppleLogoSVG,
    webpLogo: AppleLogoWebp,
    fallbackLogo: AppleLogo
  },
  {
    link: '/auth/feide_student',
    providerName: 'FEIDE',
    logo: FeideLogoSVG,
    webpLogo: FeideLogoWebp,
    fallbackLogo: FeideLogo
  }
];

export default socials;
