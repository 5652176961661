import { Translate } from 'i18n';
import { FC, useCallback, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from 'students/stores/_utils';
import { authActions, authSelectors } from 'students/stores/auth';
import { tAppState } from 'students/stores/rootStore';
import DangerIcon from 'students/views/shared/assets/icons/danger_icon.svg';
import Alert from 'students/views/shared/components/Alert';
import ResendCodeButton from 'students/views/shared/components/ResendCodeButton';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import styled from 'styled-components';
import { GoBackFooter } from '../../components';
import { SHeader, SOtpInputWrapper, SSubHeader } from '../../styled';
import { OTPReferrer } from '../types';

interface IOTPCheckForm {
  onChangeVariant: () => void;
  referrer: OTPReferrer | null;
}

const OTPCheckSmsForm: FC<IOTPCheckForm> = ({ onChangeVariant, referrer }) => {
  const dispatch = useTypedDispatch();
  const tempUser = useSelector<
    tAppState,
    ReturnType<typeof authSelectors.selectTempUser>
  >(authSelectors.selectTempUser);
  const [error, setError] = useState('');
  const [code, setCode] = useState('');

  const performSignIn = useCallback(
    async (code: string) => {
      const response = await dispatch(
        authActions.mailSignIn({
          phoneNumber: tempUser?.phoneNumber || '',
          password: code
        })
      );

      if (authActions.mailSignIn.rejected.match(response)) {
        if (response.payload) {
          setError(response.payload);
        }
      }
    },
    [dispatch, tempUser]
  );

  const onChangeHandler = (code: string) => {
    setCode(code);

    if (code.length !== 6) {
      return;
    }

    if (referrer === OTPReferrer.singIn) {
      performSignIn(code);
    }
  };

  const handleResendCode = () => {
    dispatch(
      authActions.getOTP({
        phoneNumber: tempUser?.phoneNumber as string
      })
    );
  };

  return (
    <div className="onboarding-otp-check">
      <SHeader>
        <Translate str="frontend.auth.check_your_phone_for_a_code" />
      </SHeader>

      <SSubHeader>
        <Translate
          str="frontend.auth.we_ve_sent_a_code"
          params={{
            email: tempUser ? tempUser.phoneNumber : '--',
            interpolation: { escapeValue: false }
          }}
          setDangerously
        />
      </SSubHeader>

      <SOtpInputWrapper>
        <OtpInput
          containerStyle="otp-container"
          inputStyle="otp-input"
          value={code}
          onChange={onChangeHandler}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus
          inputType="number"
        />
      </SOtpInputWrapper>

      {error && (
        <Alert>
          <SUrlIcon url={DangerIcon} height="24px" width="24px" />
          {error}
        </Alert>
      )}

      <SResendCodeButton onClick={handleResendCode} />

      <GoBackFooter isFaded str="frontend.auth.back" onChangeVariant={onChangeVariant} />
    </div>
  );
};

export default OTPCheckSmsForm;

const SUrlIcon = styled(UrlIcon)`
  display: inline-block;
  margin-inline-end: 16px;
  vertical-align: bottom;
`;

const SResendCodeButton = styled(ResendCodeButton)`
  margin: 28px 0 32px;
`;
