import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { profileActions, ILanguageInstance } from '@adeptlms/lingu-students-react-shared';
import { userSelectors } from 'students/stores/user';
import { locales, I18nContext } from 'i18n';
import { useTypedSelector } from 'students/stores/_utils';
import { useSelect } from '../LanguagesPopup';

import { useRequestAvailableLanguages } from '../hooks';

interface IReturnType {
  handleSubmit: () => void;
  sortedAllLangs: ILanguageInstance[];
  pickLang: (lanId: string | number) => void;
  pickedLanIds: string[];
}

export function useSupportLanguagesPopup(): IReturnType {
  const dispatch = useDispatch();
  const { locale } = useContext(I18nContext);
  useRequestAvailableLanguages();

  const currentOption = locales.find(({ lKey }) => lKey === locale);

  const sortedAllLangs = useTypedSelector(userSelectors.selectSortedAllLangs);
  const { pick: pickLang, pickedIds: pickedLanIds } = useSelect({
    multiple: false,
    defaultPickedIds: [`${currentOption?.lKey}`]
  });

  const handleSubmit = () => {
    dispatch(
      profileActions.selectStudentSupportLanguage({ mainLanguageId: +pickedLanIds[0] })
    );
  };
  return {
    handleSubmit,
    sortedAllLangs,
    pickLang,
    pickedLanIds
  };
}
