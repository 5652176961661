import ButtonGeneral from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import { PickedIcon } from 'students/views/shared/components/icons';
import styled from 'styled-components';

export const SListItem = styled.li`
  height: 44px;
  width: 100%;
  border-radius: 8px;
  transition: background 0.3s;
  display: flex;
  margin-block-start: 5px;
  align-items: center;
  color: var(--heading-text-color);
  position: relative;
  font-size: 1rem;
  line-height: 1.25rem;
  padding-inline-start: 12px;
  outline: none;

  &.isPicked {
    background: var(--target-language-button-background);
    font-weight: 600;
  }

  &.isActive {
    background: var(--target-language-button-background);
  }

  &:hover {
    background: var(--target-language-button-background);
    cursor: pointer;
  }
`;

export const SFlagImg = styled.img`
  width: 32px;
  height: 22px;
  margin-inline-end: 12px;
`;

export const SPicked = styled(PickedIcon)`
  position: absolute;
  inset-inline-end: 16px;
  min-width: 16px;
  min-height: 16px;
`;

export const SLanguagesListSearch = styled.div`
  position: relative;
  margin-block: 8px;
`;

export const SLanguagesListSearchInput = styled.input`
  background: transparent;
  border: none;
  border-radius: 12px;
  padding: 8px 12px;
  font-size: 1rem;
  font-weight: 400;
  font-family: var(--lingu-text-font-family);
  line-height: 1.75rem;
  color: var(--heading-text-color);
  width: 100%;

  ::-webkit-input-placeholder {
    color: var(--paragraph-text-color);
    opacity: 0.3;
  }

  &:active,
  &:focus {
    outline: none;
  }
`;

export const SCloseButton = styled(ButtonGeneral)`
  border: none;
  background: transparent;
  position: absolute;
  top: 14px;
  inset-inline-end: 12px;

  &:active,
  &:focus {
    outline: none;
  }
`;

export const SNoResultsMessage = styled.h2`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: var(--heading-text-color);
  margin: 1rem 0;
`;

export const SList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: max-content;
  max-height: 290px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &:focus-visible {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 4px;
    border-radius: 5px;
  }
`;

export const SSecondaryText = styled.span`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--sub-text-color);
  margin-block-start: 2px;
  margin-inline-start: 8px;
`;
