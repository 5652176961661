import { FC } from 'react';
import MyLessonsIcon from 'students/views/pages/Member/components/navigation/components/icons/Catalog';
import HomeIcon from './Home';
import AssignmentsIcon from './Assignments';
import SettingsIcon from './Settings';
import MeetingsIcon from './Meetings';
import BoardsIcon from './Boards';
import MessagesIcon from './Messages';
// import LiveClassesIcon from './LiveClasses'

const navigationIconsMap = new Map<string, FC>([
  ['home', HomeIcon],
  ['catalog', MyLessonsIcon],
  ['assignments', AssignmentsIcon],
  ['settings', SettingsIcon],
  ['meetings', MeetingsIcon],
  ['boards', BoardsIcon],
  ['live_classes', MeetingsIcon], // TODO When LiveClasses are 100% live - replace the icon with a proper one
  ['messages', MessagesIcon]
]);

export { navigationIconsMap };
