const userVariables = {
  linguBptXs: '320px',
  linguBptSm: '576px',
  linguBptMd: '768px',
  linguBptLg: '992px',
  linguBptXl: '1200px',
  linguBrPts: {
    xs: 320,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },

  mediaQueryBreakpoints: {
    mobile: 320,
    tablet: 644,
    desktop: 908
  },

  sidebarNavigationWidth: '290px'
};

export default userVariables;
