import React, { FC, ComponentType } from 'react';
import LinkElem from 'students/views/shared/components/LinkElem';
import styled from 'styled-components';

interface IWithLessonCardLink {
  lesson: { id: number } & any;
  [key: string]: any;
}

function withLessonCardLink(
  WrappedComponent: ComponentType<IWithLessonCardLink>
): FC<IWithLessonCardLink> {
  const hocComponent: FC<IWithLessonCardLink> = ({ lesson, ...props }) => (
    <SLink to={`/lessons/${lesson.id}`}>
      <WrappedComponent lesson={lesson} {...props} />
    </SLink>
  );

  return hocComponent;
}

export default withLessonCardLink;

const SLink = styled(LinkElem)`
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: inherit;
    text-decoration: inherit;
  }

  &:visited {
    color: inherit;
    text-decoration: inherit;
  }
`;
