import React, { FC } from 'react';
import ReduxModal from './ReduxModal';
import { tWithReduxModalProps } from './types';

type tOptions = {
  preventClose?: boolean;
  preventDefaultModalStyles?: boolean;
  backdropClassName?: string;
  containerClassName?: string;
  dialogClassName?: string;
};

function withReduxModal(
  modalKey: string,
  options?: tOptions
): <P extends Record<string, any>>(WrappedComponent: React.ComponentType<P>) => FC<P> {
  function wrapper<P extends Record<string, any>>(
    WrappedComponent: React.ComponentType<P & tWithReduxModalProps>
  ): FC<P> {
    const hocComponent: FC<P> = ({ ...props }) => (
      <ReduxModal
        modalKey={modalKey}
        preventClose={options?.preventClose}
        backdropClassName={options?.backdropClassName}
        containerClassName={options?.containerClassName}
        dialogClassName={options?.dialogClassName}
      >
        {(close, context) => (
          <WrappedComponent close={close} context={context} {...props} />
        )}
      </ReduxModal>
    );

    return hocComponent;
  }

  return wrapper;
}

export default withReduxModal;
