import { IProfile } from '@adeptlms/lingu-students-react-shared';
import { FC } from 'react';
import { SignInButton } from 'students/views/screens/Auth';
import styled from 'styled-components';

import LinkElem from '../../LinkElem';
import UserAvatar from './UserAvatar';

interface IUserProfileButton {
  user: IProfile | null;
}

const UserProfileButton: FC<IUserProfileButton> = ({ user }) => {
  if (!user) {
    return (
      <SButtonWrapper>
        <SignInButton primary />
      </SButtonWrapper>
    );
  }

  return (
    <SUserProfileButton>
      <SUserAvatarLink to="/">
        <UserAvatar user={user} />
      </SUserAvatarLink>
    </SUserProfileButton>
  );
};

export default UserProfileButton;

const SUserProfileButton = styled.div`
  background: rgba(255, 255, 255, 0.98);
  border-radius: 36px;
  padding: 2px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const SUserAvatarLink = styled(LinkElem)`
  cursor: pointer;
  border-radius: 2.25rem;
  outline-offset: 5px;

  &:hover {
    text-decoration: none;
  }
`;

const SButtonWrapper = styled.div`
  margin-inline-start: auto;
`;
