import { IProfile } from '@adeptlms/lingu-students-react-shared';
import { Translate, t } from 'i18n';
import React, { useCallback, useState } from 'react';
import { useTypedDispatch } from 'students/stores/_utils';
import { authActions } from 'students/stores/auth';
import SignInButton from 'students/views/screens/Auth/components/SignInButton';
import CloseIcon from 'students/views/shared/assets/icons/close_icon.svg';
import MenuIcon from 'students/views/shared/assets/icons/menu_icon.svg';
import LightLogoIcon from 'students/views/shared/assets/lingu-light-logo.svg';
import LogoIcon from 'students/views/shared/assets/lingu-logo.svg';
import { HeadlessModal } from 'students/views/shared/components/modal';
import styled, { css } from 'styled-components';

import ButtonGeneral, { styleInnerButton } from '../ButtonGeneral/ButtonGeneral';
import LinkElem from '../LinkElem';
import UrlIcon from '../UrlIcon';
import LanguageListSwitcher from './LanguageListSwitcher';
import { UserAvatar } from './UserProfile';

interface IProps {
  darkIcon?: boolean;
  user: IProfile | null;
}
const Burger: React.FC<IProps> = ({ darkIcon, user }) => {
  const burgerIconColor = darkIcon ? '#2d2d3a' : 'white';
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useTypedDispatch();

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, [setShowMenu]);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, [setShowMenu]);

  const signInClickHandler = useCallback(() => {
    closeMenu();
  }, [closeMenu]);

  const handleLogOut = useCallback(() => {
    dispatch(authActions.logOut());
  }, [dispatch]);

  return (
    <div>
      <SMenuBurgerWrapper>
        <SBurgerButton
          variant="link"
          className="md:ln-hidden"
          onClick={openMenu}
          aria-label={t('frontend.lesson_page.hamburger_menu_btn')}
        >
          <UrlIcon url={MenuIcon} color={burgerIconColor} height="26px" width="26px" />
        </SBurgerButton>

        <SLogoLink to="/" title="Lingu">
          <UrlIcon url={LightLogoIcon} height="46px" width="80px" />
        </SLogoLink>
      </SMenuBurgerWrapper>

      <HeadlessModal
        modalKey="burger-modal"
        show={showMenu}
        containerClassName="ln-w-full ln-bg-white ln-p-6"
        dialogClassName="ln-w-full ln-relative"
      >
        <SMenuBody>
          <SCloseButton variant="link" onClick={closeMenu}>
            <UrlIcon url={CloseIcon} color="#5E5D71" height="22px" width="22px" />
          </SCloseButton>

          <div className="menu__top">
            <SLogoLink to="/">
              <img src={LogoIcon} alt="Lingu" />
            </SLogoLink>

            <SButtonsWrapper>
              {!!user && (
                <SAvatarLink to="/">
                  <UserAvatar user={user} responsive={false} />
                </SAvatarLink>
              )}

              {!user ? (
                <>
                  <SLanguageListSwitcher />
                  <SSignInButton primary onClick={signInClickHandler} />
                </>
              ) : (
                <SLogoutButton onClick={handleLogOut}>
                  <Translate str="frontend.profile.navigation.log_out" />
                </SLogoutButton>
              )}
            </SButtonsWrapper>
          </div>
        </SMenuBody>
      </HeadlessModal>
    </div>
  );
};

export default Burger;

const SMenuBody = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .menu__top,
  .menu__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
  }
`;

const SMenuBurgerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SButtonsWrapper = styled.div`
  width: 100%;
`;

const SLogoLink = styled(LinkElem)`
  margin-inline-start: 4px;

  img {
    width: 8.75rem;
    height: 4rem;
    object-fit: cover;
  }
`;

const SButtonStyles = css`
  border-radius: 62px;
  width: 100%;
  letter-spacing: -0.02em;
  font-size: 1rem;
  line-height: 1.21rem;
  margin: 0 0 1.75rem;
  text-align: center;

  ${styleInnerButton()} {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const SLanguageListSwitcher = styled(LanguageListSwitcher)`
  ${SButtonStyles};
  background: var(--color-light-grey);
  margin-block-start: 1rem;

  ${styleInnerButton()} {
    &:after {
      position: static;
      display: flex;
      align-items: center;
      margin-inline-start: 0.625rem;
    }
  }
`;

const SSignInButton = styled(SignInButton)`
  ${SButtonStyles};
`;

const SAvatarLink = styled(LinkElem)`
  min-height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-block-end: 3.438rem;
  margin-block-start: 4.5rem;
  padding: 6px 8px;
  border-radius: 2.2rem;
  background: var(--color-white);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    text-decoration: none;
  }

  article {
    & > span {
      font-size: 1.125rem;
      font-weight: 500;
      letter-spacing: -0.02em;
      margin-inline-start: 2rem;
    }
    flex: 1;
    justify-content: center;
  }

  img {
    max-height: 2.8rem;
    max-width: 2.8rem;
    width: 100%;
    height: 100%;
  }
`;

const SLogoutButton = styled(ButtonGeneral)`
  ${SButtonStyles};
  background: var(--color-light-grey);
  color: var(--color-grey);
  font-family: var(--lingu-text-font-family);
  font-weight: 500;
  font-size: 1rem;

  &:hover {
    text-decoration: none;
    color: #5959bc;
  }

  &:last-child {
    margin-block-end: 0;
  }
`;

const SBurgerButton = styled(ButtonGeneral)`
  background-color: transparent;

  ${styleInnerButton()} {
    padding: 0.375rem 0.75rem;
  }
`;

const SCloseButton = styled(ButtonGeneral)`
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  background-color: transparent;
  padding-top: env(safe-area-inset-top);

  ${styleInnerButton()} {
    padding: 0.375rem;
  }
`;
