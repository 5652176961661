import { v4 as uuidv4 } from 'uuid';
import { ITaskAudioManagerItem, tTaskAudioManagerItems } from './types';
import TaskAudioRepository, {
  ITaskAudioRepositoryCollectionItem,
  tTaskAudioRepositoryCollection
} from './TaskAudioRepository';

export interface IAudioManagerReturnedObject {
  manager: TaskAudioManager;
  add: (item: ITaskAudioManagerItem) => void;
  addUnique: (item: ITaskAudioManagerItem) => void;
  reset: () => void;
  unregister: (id: string | number) => void;
  unregisterUncached: (id: string | number) => void;
  getAll: () => tTaskAudioRepositoryCollection;
  stop: () => void;
  unsubscribe: () => void;
  playById: (id: string | number) => void;
  getById: (id: string | number) => ITaskAudioRepositoryCollectionItem | null;
  visibilityPlay: () => void;
  getByTag: (tag: string) => tTaskAudioRepositoryCollection;
  unload: () => void;
  visibilityPause: () => void;
  addTagToMute: (tag: string) => void;
  removeTagFromMute: (tag: string) => void;
}
export default class TaskAudioManager {
  private repository: TaskAudioRepository;
  private mutedTags: string[] = [];
  name: string;

  constructor(items: tTaskAudioManagerItems | null) {
    this.repository = new TaskAudioRepository(items);
    this.name = uuidv4();
  }

  /**
   * Add new audio
   */
  add(item: ITaskAudioManagerItem): void {
    this.repository.add(item);
  }

  /**
   * Add only new unique audio
   */
  addUnique(item: ITaskAudioManagerItem): void {
    !this.repository.findOneBySrc(item.src) && this.repository.add(item);
  }

  /**
   * Reset manager
   */
  reset(): void {
    this.unsubscribe();
    this.unload();
    this.repository.reset();
  }

  /**
   * Unregister track
   */
  unregister(id: string | number): void {
    const track = this.repository.findOneById(id);
    if (track) {
      track.player.unsubscribe();
      track.player.unload();

      this.repository.delete(id);
    }
  }

  /**
   * Unregister uncached track
   */
  unregisterUncached(id: string | number): void {
    this.repository.findOneUncachedById(id) && this.unregister(id);
  }

  /**
   * Unsubscribe track progress for all audio
   */
  unsubscribe(): void {
    this.repository.findAll()?.forEach((item) => {
      item.player.unsubscribe();
    });
  }

  /**
   * Unload and destroy a player object.
   * This will immediately stop all sounds attached to this sound and remove it from the cache.
   */
  unload(): void {
    this.repository.findAll()?.forEach((item) => {
      item.player.unload();
    });
  }

  /**
   * Stop all audio
   */
  stop(): void {
    this.repository.findByStatus('played').forEach((item) => {
      item.player.stop();
    });
  }

  /**
   * Pause all played audio
   */
  visibilityPause(): void {
    this.repository.findByStatus('played').forEach((item) => {
      item.player.visibilityPause();
    });
  }

  /**
   * Play all audio that was paused by visibility reason
   */
  visibilityPlay(): void {
    this.repository.findByStatus('visible-paused').forEach((item) => {
      item.player.play();
    });
  }

  /**
   * Get audio by id: configured player, id, tags and many other information ...
   *
   * @param id
   */
  getById(id: string | number): ITaskAudioRepositoryCollectionItem | null {
    return this.repository.findOneById(id);
  }

  /**
   * Get audio by tag: configured player, id, tags and many other information ...
   *
   * @param tag
   */
  getByTag(tag: string): tTaskAudioRepositoryCollection {
    return this.repository.findByTag(tag);
  }

  /**
   * Get all audio: configured player, id, tags and many other information ...
   *
   * @param tag
   */
  getAll(): tTaskAudioRepositoryCollection {
    return this.repository.findAll();
  }

  private shouldItemBeMuted(tags: string[]): boolean {
    return this.mutedTags.some((mutedTag) => tags.includes(mutedTag));
  }

  /**
   * Play audio by id
   *
   * @param id
   */
  playById(id: string | number): void {
    const item = this.repository.findOneById(id);

    if (item && !this.shouldItemBeMuted(item.tags)) {
      item.player.play();
    }
  }

  addTagToMute(tag: string): void {
    !this.mutedTags.includes(tag) && this.mutedTags.push(tag);
  }

  removeTagFromMute(tag: string): void {
    this.mutedTags = this.mutedTags.filter((mutedTag) => tag !== mutedTag);
  }
}
