import cn from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import Navbar from 'students/views/shared/components/Navbar';
import PageWithBg from 'students/views/shared/components/PageWithBg';
import { SPageContainer, SPageWrapper } from 'students/views/shared/styled';
import { NavBar as Sidebar } from './Member/components/navigation';

export interface IPage {
  background?: string;
  gradient?: string;
  bgImage?: string;
  bgSize?: string;
  style?: React.CSSProperties;
  hasNavbar?: boolean;
  hasSidebar?: boolean;
  className?: string;
  noPadding?: boolean;
  backPath?: string;
  PageNavigation?: React.ReactNode;
}

const Page: FC<PropsWithChildren<IPage>> = ({
  children,
  background,
  gradient,
  bgImage,
  bgSize,
  hasNavbar,
  hasSidebar,
  style,
  className,
  noPadding,
  backPath,
  PageNavigation
}) => {
  return (
    <PageWithBg
      gradient={gradient}
      solidColor={background}
      bgImage={bgImage}
      bgSize={bgSize}
      style={style}
    >
      {!!hasNavbar && <Navbar backTo={backPath} />}

      <div className="ln-min-h-screen ln-min-w-[320px] ln-max-w-screen ln-flex ln-flex-col xl:ln-flex-row [&>main]:ln-flex-1">
        {!!hasSidebar && <Sidebar mobileVariant="small" />}

        <SPageWrapper>
          {PageNavigation}

          <SPageContainer className={cn(className)} noPadding={noPadding}>
            {children}
          </SPageContainer>
        </SPageWrapper>
      </div>
    </PageWithBg>
  );
};

export default Page;
