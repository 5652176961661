import React from 'react';
import styled from 'styled-components';

import Spinner, { tSpinnerVariant } from '../Spinner/Spinner';
import { withErrorBoundary } from '../../HOCs';

export interface IButtonGeneral extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  block?: boolean;
  variant?: 'primary' | 'link' | 'light';
  showSpinner?: boolean;
  spinnerVariant?: tSpinnerVariant;
  buttonRef?: React.ForwardedRef<HTMLButtonElement | null>;
}

export const BUTTON_INNER_CLASS_NAME = 'button-inner';

const ButtonGeneralComponent: React.FC<IButtonGeneral> = withErrorBoundary(
  ({
    children,
    onClick,
    block,
    variant,
    showSpinner,
    spinnerVariant,
    buttonRef,
    ...props
  }) => {
    return (
      <SButtonGeneral
        onClick={onClick}
        block={block}
        variant={variant}
        ref={buttonRef}
        {...props}
      >
        <div className={BUTTON_INNER_CLASS_NAME} tabIndex={-1}>
          {showSpinner ? <Spinner variant={spinnerVariant} /> : children}
        </div>
      </SButtonGeneral>
    );
  }
);

const SButtonGeneral = styled.button<IButtonGeneral>`
  border: 1px solid transparent;
  text-align: center;
  user-select: none;
  padding: 0;
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  width: ${({ block }) => (block ? '100%' : 'auto')};

  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return `
          color: var(--button-primary-text);
          background-color: var(--button-primary-bg);
          border-color: var(--color-blue);
        `;

      case 'link':
        return `
          font-weight: 400;
          color: var(--color-blue);
          text-decoration: none;
        `;

      case 'light':
        return `
          color: #344050;
          background-color: #f9fafd;
          border-color: #f9fafd;
        `;

      default:
        return 'color: var(--color-white);';
    }
  }};

  &:focus,
  > ${`.${BUTTON_INNER_CLASS_NAME}`}:focus {
    outline: none;
  }

  > .${BUTTON_INNER_CLASS_NAME} {
    height: 100%;
    width: 100%;
    min-height: inherit;
    border-radius: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:focus > .${BUTTON_INNER_CLASS_NAME} {
    outline: 3px solid var(--color-blue-btn-focus-outline-color);
    outline-offset: 5px;
    border-radius: 5px;
  }

  &:disabled {
    opacity: 0.65;
    pointer-events: none;
  }
`;

type tPseudo = 'hover' | 'focus' | 'active';
export function styleInnerButton(pseudoClass?: tPseudo): string {
  if (pseudoClass) return `&:${pseudoClass} > .${BUTTON_INNER_CLASS_NAME}`;
  return `& > .${BUTTON_INNER_CLASS_NAME}`;
}

export default React.forwardRef<HTMLButtonElement | null, IButtonGeneral>(
  (props, forwardedRef) => {
    return <ButtonGeneralComponent {...props} buttonRef={forwardedRef} />;
  }
);
