import React, { FC, RefObject, useCallback } from 'react';
import { Translate } from 'i18n';
import { useOutsideClick } from 'students/views/shared/hooks';
import {
  STitle,
  SCancel,
  SNext,
  SContent,
  SWrapper,
  SButtons,
  SCloseButton,
  SCloseIcon,
  SBackButton,
  SBackArrowIcon
} from './styles';
import { useLangSelect } from './useLangSelect';

interface IStepComponent {
  titleLocaleKey: string;
  component: JSX.Element;
  handleSubmit: () => void;
}

interface ILanguagesPopupProps {
  steps: IStepComponent[];
  menuRef: RefObject<HTMLDivElement>;
  modalKey: string;
}

export const LanguagesPopup: FC<ILanguagesPopupProps> = ({
  steps,
  menuRef,
  modalKey
}) => {
  const { nextStep, prevStep, closeModal, isFirstStep, isLastStep, step } = useLangSelect(
    {
      quantitySteps: steps.length
    }
  );
  const handleClosePopup = useCallback(() => {
    closeModal(modalKey);
  }, [closeModal, modalKey]);
  useOutsideClick(menuRef, handleClosePopup, []);
  const { titleLocaleKey, component, handleSubmit } = steps[step];
  const next = () => {
    if (isLastStep) {
      handleSubmit();
      handleClosePopup();
      return;
    }
    nextStep();
  };

  return (
    <div ref={menuRef}>
      <SCloseButton data-testid="LangSelect_Cross" onClick={handleClosePopup}>
        <SCloseIcon />
      </SCloseButton>

      <SWrapper>
        <STitle data-testid="LangSelect_Title">
          {!isFirstStep && (
            <SBackButton onClick={prevStep}>
              <SBackArrowIcon />
            </SBackButton>
          )}

          <Translate str={titleLocaleKey} />
        </STitle>

        <SContent data-testid="LangSelect_component">{component}</SContent>
      </SWrapper>

      <SButtons>
        <SCancel
          variant="secondary-dark"
          data-testid="LangSelect_Cancel"
          onClick={handleClosePopup}
        >
          <Translate str="frontend.member.lang_popup.cancel" />
        </SCancel>

        <SNext variant="primary" data-testid="LangSelect_Next" onClick={next}>
          <Translate str={`frontend.member.lang_popup.${isLastStep ? 'save' : 'next'}`} />
        </SNext>
      </SButtons>
    </div>
  );
};
