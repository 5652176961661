import React, { useMemo } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Translate } from 'i18n';

import { navigationIconsMap } from './icons';
import { INavigationItem } from '../config';

interface IProps {
  item: INavigationItem;
}

const TabBarItem: React.FC<IProps> = ({ item }) => {
  const Icon = useMemo(() => navigationIconsMap.get(item.name), [item.name]);

  return (
    <STabBarItem
      to={item.path}
      key={item.name}
      $color={item.color}
      activeClassName="active"
      isActive={(match) => !!match && match.isExact}
    >
      {Icon && <Icon />}
      <SCaption>
        <Translate scope="frontend.mobile_tabs" str={item.name} />
      </SCaption>
    </STabBarItem>
  );
};

export default TabBarItem;

const STabBarItem = styled(NavLink)<{ $color: string }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  color: var(--color-grey);
  font-size: 0.7rem;
  overflow: hidden;

  path {
    transition: all 0.3s ease;
  }

  &.active {
    color: ${({ $color }) => $color};
    text-decoration: none;
  }
`;

const SCaption = styled.p`
  padding: 0.2rem 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
