import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

export const SFaddedText = styled.span`
  color: var(--color-grey);
`;

export const SHeader = styled.h1`
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-weight: bold;
  text-align: center;
  margin-block-end: 2rem;

  ${customMediaQuery('tablet')} {
    font-size: 1.75rem;
  }
`;

export const SSubHeader = styled.h2`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-align: center;
  margin-block-end: 1.5rem;

  ${customMediaQuery('tablet')} {
    margin-block-end: 3.25rem;
  }
`;

export const SFooter = styled.div`
  font-family: 'Inter', 'Product Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-start: 2rem;
`;

export const SFooterButton = styled.button<{ color: string }>`
  text-decoration: none;
  color: ${({ color }) => color};
  display: flex;
  align-items: center;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: ${({ color }) => color};
  }

  div {
    margin-inline-end: 8px;
  }
`;

export const SInput = styled.input`
  border-radius: 10px;
  min-height: 3.5rem;
  background-color: transparent;
  margin-block-end: 1.25rem;
  border-color: #e6e6f0;
  border-width: 1px;
  border-style: solid;
  padding: 0 1rem;
  width: 100%;
`;

export const SSelect = styled(SInput)`
  padding: 0 2rem 0 1rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.00488 4.37988L6.00037 8.3754L9.99588 4.37988' stroke='%232D2D3A' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
`;

export const SOtpInputWrapper = styled.div`
  margin-block-end: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .otp-container {
  }

  .otp-input {
    border: 1px solid #e6e6f0;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: var(--color-black);
    width: 44px !important;
    height: 46px;
    margin: 5px;
    padding: 12px;

    ${customMediaQuery('tablet')} {
      width: 60px !important;
      height: 66px;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--color-black);
    }
  }
`;
